import LoginView from '../components/views/Login.vue'
import PageView from '../components/views/Page.vue'
//import DashboardView from '../components/dashboard/Dashboard.vue'
//import BacklogView from '../components/dashboard/Backlog.vue'

// Routes
const routes = [
    {
        path: '/login',
        component: LoginView
    },
    {
        path: '*',
        component: PageView
    }
]

export default routes