export default {
    TOGGLE_LOADING(state) {
        state.callingAPI = !state.callingAPI
    },
    /*SET_LOADING(loading) {
        state.callingAPI = loading
    },*/
    TOGGLE_SEARCHING(state) {
        state.searching = (state.searching === '') ? 'loading' : ''
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_USERINFO(state, userInfo) {
        state.userInfo = userInfo
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    SET_TOKEN(state, token) {
        state.token = token
    },
    SET_WS_INFO(state, websiteInfo) {
        state.websiteInfo = websiteInfo
    },
    SAVE_DATA(state) {
        state.saveData = true
    },
    DATA_SAVED(state) {
        state.saveData = false
    },
    SET_APPDATA(state, params) {
        state.appData = state.appData || {};
        state.appData[params.culture] = params.data;
        localStorage.setItem('appData', JSON.stringify(state.appData))
    },
    TOGGLE_DEVMODE(state) {
        state.devMode = !state.devMode
        state.adminMode = false;
        sessionStorage.setItem('devMode', JSON.stringify(state.devMode));
        sessionStorage.setItem('adminMode', JSON.stringify(state.adminMode));
    },
    TOGGLE_ADMINMODE(state) {
        state.adminMode = !state.adminMode
        state.devMode = false;
        sessionStorage.setItem('devMode', JSON.stringify(state.devMode));
        sessionStorage.setItem('adminMode', JSON.stringify(state.adminMode));
    },
    TOGGLE_REFRESHSERVERCACHE(state) {
        state.refreshServerCache = !state.refreshServerCache
        sessionStorage.setItem('refreshServerCache', JSON.stringify(state.refreshServerCache));
    },
    SET_DATACACHE(state, params) {
        //debugger
        state.dataCache[params.culture] = state.dataCache[params.culture] || {};
        state.dataCache[params.culture][params.path] = params.data;
        sessionStorage.setItem('dataCache', JSON.stringify(state.dataCache));
    },
    SET_TEMPLATECACHE(state, params) {
        //debugger
        state.templateCache[params.id] = params.data;
        localStorage.setItem('templateCache', JSON.stringify(state.templateCache));
    },
    CLEAR_APPDATA(state) {
        state.appData = {}
        localStorage.removeItem('appData')
    },
    CLEAR_TEMPLATES(state) {
        state.templateCache = {}
        localStorage.removeItem('templateCache')
    },
    CLEAR_DATACACHE(state) {
        state.dataCache = {}
        sessionStorage.removeItem('dataCache')
    },
    SET_CURRENTWEBSITE(state, website) {
        state.currentWebsite = website;
    }
}
