<template>
    <div class="html-input" v-html="valueModel" contenteditable="true" :data-placeholder="this.showPlaceholder ? placeholder : ''" @input="updateHtml" @blur="updateValue"> </div>
</template>
<script>
    export default {
        name: 'HtmlInput',
        props: {
            value: { type: String, default: '' },
            placeholder: { type: String, default: '' }
        },
        data() {
            return {
                valueModel: this.value,
                showPlaceholder: this.$el && this.$el.innerText.replace(/[\n ]*$/m, '').length == 0
            }
        },
        methods: {
            updateHtml() {
                //debugger
                this.showPlaceholder = this.$el.innerText.replace(/[\n ]*$/m, '').length == 0
                this.$emit('input', this.$el.innerHTML)
            },
            updateValue: function () {

            }
        },
        mounted() {
            this.valueModel = this.valueModel || '';
            this.$emit('input', this.valueModel)
        }
    }
</script>

<style scoped>
    .html-input::before {
        content: attr(data-placeholder);
        color: var(--placeholder-color);
        position: absolute
    }

</style>