import AuthProvider from './AuthProvider'

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default {
    name: 'DataProvider',
    //baseUrl: 'https://' + (document.location.hostname.includes('localhost') ? 'abreu-b2b-staging.viatecla.com': document.location.hostname),
    baseUrl: document.location.protocol + '//' + document.location.hostname, 
    _pendingTemplates: [],

    getConfig(culture, $store) {
        let DataProvider = this;

        return new Promise((resolve, reject) => {
            if ($store && $store.state && $store.state.appData && $store.state.appData[culture]) {
                resolve($store.state.appData[culture])
            }
            else {
                $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/data/config?culture=' + encodeURIComponent(culture) +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''), {
                    method: 'GET'
                })
                    .then(response => {
                        if (response.status != 200) {
                            reject(response.statusText);
                            return;
                        }
                        response.json().then((response) => {

                            /* Checking if error object was returned from the server */
                            if (response.Error) {
                                $store.state.serverPending--;
                                $store.state.serverFailed++;
                                reject(response.Message);
                            }
                            else {
                                $store.state.serverPending--;
                                $store.state.serverSuccess++;
                                $store && $store.commit('SET_APPDATA', { culture, data: response });
                                resolve(response);
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getPropsData(path, culture, $store, forceOwn) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {

            if ($store && $store.state && !$store.state.adminMode && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][path]) {
                if (forceOwn && $store.state.dataCache[culture][path].Child)
                    resolve($store.state.dataCache[culture][path].Child)
                else
                    resolve($store.state.dataCache[culture][path])
            }
            else {
                $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/data/channelproperties?path=' + encodeURIComponent(path) + '&culture=' + encodeURIComponent(culture) +
                    (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '') +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : '') +
                    ($store && $store.state.adminMode ? '&admin=1' : ''), {
                    method: 'GET'
                })
                    .then(response => {
                        response.json().then((response) => {
                        /* Checking if error object was returned from the server */
                            if (response.Error) {
                                if (response.Message && response.Message.includes("Invalid authentication") && AuthProvider._options.k4t) {
                                    AuthProvider.$router.push("/logout");
                                }
                                else {
                                    $store.state.serverPending--;
                                    $store.state.serverFailed++;
                                    reject(response.Message);
                                }
                            }
                            else if (response.Data && response.Data.length) {
                                $store.state.serverPending--;
                                $store.state.serverSuccess++;
                                var own = response.Data.shift();
                                var parent = response.Data.pop();
                                if (parent)
                                    parent.Child = own
                                $store && !$store.state.adminMode && $store.commit('SET_DATACACHE', { culture, path, data: parent || own })
                                resolve(forceOwn ? own : (parent || own));
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getContentData(path, culture, $store, refreshcache) {
        let DataProvider = this;

        return new Promise((resolve, reject) => {
            if (!refreshcache && $store && !$store.state.adminMode && $store.state && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][path]) {
                resolve($store.state.dataCache[culture][path])
            }
            else {
                $store && $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/data/content?path=' + encodeURIComponent(path) + '&culture=' + encodeURIComponent(culture) +
                    (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '') +
                    (refreshcache || $store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : '') +
                    ($store && $store.state.adminMode ? '&admin=1' : ''), {
                    method: 'GET'
                })
                    .then(response => {
                        response.json().then((response) => {
                            //debugger;
                            /* Checking if error object was returned from the server */
                            if (response.Error) {
                                $store && $store.state.serverPending--;
                                $store && $store.state.serverFailed++;
                                reject(response.Message);
                            }
                            else if (response.Data) {
                                $store && $store.state.serverPending--;
                                $store && $store.state.serverSuccess++;
                                $store && !$store.state.adminMode && $store.commit('SET_DATACACHE', { culture, path, data: response.Data })
                                resolve(response.Data);
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getChannelItems(channel, start, max, sort, search, culture, $store, abort, anonymous) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            
            let cacheKey = channel + '_' + start + '_' + max + '_' + sort + '_' + search

            if ($store && $store.state && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][cacheKey]) {
                resolve($store.state.dataCache[culture][cacheKey])
            }
            else {
                $store && $store.state && $store.state.serverPending++;
                var idOrPath = '&path=' + encodeURIComponent(channel.replace(/\/props_[^/]*$/, ''));

                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(channel)) {
                    idOrPath = `&id=${channel}`;
                }
                fetch(DataProvider.baseUrl + '/data/QueryContents?' +
                    '&skip=' + start +
                    '&take=' + max +
                    '&orderBy=' + sort +
                    (search ? '&query=' + search + '' : ($store ? '&query=%23id;%;like' : '&query=%23id;' + uuidv4() + ';<>')) +
                    idOrPath +
                    '&culture=' + encodeURIComponent(culture) +
                    (!anonymous && AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '') +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''),
                    {
                        method: 'GET',
                        signal: abort && abort.signal
                    })
                    .then(response => {
                        response.json().then((response) => {
                            //debugger;
                            /* Checking if error object was returned from the server */
                            if (response.Error) {
                                $store && $store.state && $store.state.serverPending--;
                                $store && $store.state && $store.state.serverFailed++;
                                reject(response.Message);
                            }
                            else if (response.Data) {
                                $store && $store.state && $store.state.serverPending--;
                                $store && $store.state && $store.state.serverSuccess++;
                                $store && $store.commit('SET_DATACACHE', { culture, path: cacheKey, data: response.Data.Contents })
                                resolve(response.Data.Contents);
                            }
                        });
                    })
                    .catch(error => {
                        $store && $store.state && $store.state.serverPending--;
                        $store && $store.state && $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getTemplate(template, $store, full) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            if (template == '00000000-0000-0000-0000-000000000000')
                resolve('<template><pre>Undefined Template</pre></template><script>export default {}</script><style></style>');
            else {
                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(template)) {
                    if ($store && $store.state && $store.state.templateCache && $store.state.templateCache[template.toLowerCase()]) {
                        resolve(full ? $store.state.templateCache[template.toLowerCase()] : $store.state.templateCache[template.toLowerCase()].Template.replace(/\r?\n/g, ''))
                    }
                    else if (DataProvider._pendingTemplates.indexOf(template.toLowerCase()) > -1) {
                        setTimeout(() => {
                            DataProvider.getTemplate(template, $store, full).then(t => resolve(t));
                        }, 50)
                    }
                    else {
                        DataProvider._pendingTemplates = DataProvider._pendingTemplates || [];
                        DataProvider._pendingTemplates.push(template.toLowerCase())
                        $store.state.serverPending++;
                        fetch(DataProvider.baseUrl + '/data/template?templateId=' + template +
                            ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''), {
                            method: 'GET'
                        })
                            .then(response => {
                                response.json().then((response) => {
                                    /* Checking if error object was returned from the server */
                                    if (response.Error) {
                                        $store.state.serverPending--;
                                        $store.state.serverFailed++;
                                        reject(response.Message);
                                    }
                                    else if (response.Data && response.Data.Template) {
                                        //debugger
                                        $store.state.serverPending--;
                                        $store.state.serverSuccess++;
                                        $store && $store.commit('SET_TEMPLATECACHE', { id: template.toLowerCase(), data: response.Data })
                                        DataProvider._pendingTemplates = DataProvider._pendingTemplates.filter((t) => t != template.toLowerCase());
                                        resolve(full ? response.Data : response.Data.Template.replace(/\r?\n/g, ''));
                                    }
                                });
                            })
                            .catch(error => {
                                $store.state.serverPending--;
                                $store.state.serverFailed++;
                                reject(error)
                            });
                    }
                }
                else {
                    resolve('<template><span class="danger">Invalid template ID</span></template><script>export default {}</script><style></style>');
                }
            }
        });
    },
    getSchema(id, $store/*, full*/) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            if (id == '00000000-0000-0000-0000-000000000000')
                resolve({});
            else {
                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(id)) {
                    $store.state.serverPending++;
                    fetch(DataProvider.baseUrl + '/data/schema?schemaID=' + id +
                        ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''), {
                        method: 'GET'
                    })
                        .then(response => {
                            response.json().then((response) => {
                                /* Checking if error object was returned from the server */
                                if (response.Error) {
                                    $store.state.serverPending--;
                                    $store.state.serverFailed++;
                                    reject(response.Message);
                                }
                                else if (response.Data) {
                                    //debugger
                                    $store.state.serverPending--;
                                    $store.state.serverSuccess++;
                                    resolve(response.Data);
                                }
                            });
                        })
                        .catch(error => {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(error)
                        });
                }
                else {
                    resolve({ error: 'Invalid schema ID' });
                }
            }
        });
    },
    getChannelSchema(id, $store/*, full*/) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            if (id == '00000000-0000-0000-0000-000000000000')
                resolve({});
            else {
                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(id)) {
                    $store.state.serverPending++;
                    fetch(DataProvider.baseUrl + '/data/channelschema?id=' + id +
                        ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''), {
                        method: 'GET'
                    })
                        .then(response => {
                            response.json().then((response) => {
                                /* Checking if error object was returned from the server */
                                if (response.Error) {
                                    $store.state.serverPending--;
                                    $store.state.serverFailed++;
                                    reject(response.Message);
                                }
                                else if (response.Data) {
                                    //debugger
                                    $store.state.serverPending--;
                                    $store.state.serverSuccess++;
                                    resolve(response.Data);
                                }
                            });
                        })
                        .catch(error => {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(error)
                        });
                }
                else {
                    resolve({ error: 'Invalid schema ID' });
                }
            }
        });
    },
    getContentTemplate(content, $store) {
        return this.getTemplate(content.Template.Id, $store)
    },
    getTranslations(keys, culture, $store) {
        let DataProvider = this;
        if (!keys)
            return null;
        return new Promise((resolve, reject) => {
            $store.state.serverPending++;

            fetch(DataProvider.baseUrl + '/data/Translate?culture=' + culture, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: "POST",
                body: keys.map(k => 'keys=' + encodeURIComponent(k)).join('&')
            })
                .then(response => {
                    response.json().then((response) => {
                        //debugger;
                        /* Checking if error object was returned from the server */
                        if (!response) {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(null);
                        }
                        else {
                            $store.state.serverPending--;
                            $store.state.serverSuccess++;
                            resolve(response);
                        }
                    });
                })
                .catch(error => {
                    $store.state.serverPending--;
                    $store.state.serverFailed++;
                    reject(error)
                })
        });
    },
    getChannelTree(channel, $store) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            $store.state.serverPending++;
            fetch(DataProvider.baseUrl + '/data/ChannelTree?id=' + channel
                + (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '')
                /*+ '&culture=' + culture*/, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    method: "GET"
                })
                .then(response => {
                    response.json().then((response) => {
                        //debugger;
                        /* Checking if error object was returned from the server */
                        if (!response) {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(null);
                        }
                        else {
                            $store.state.serverPending--;
                            $store.state.serverSuccess++;
                            resolve(response);
                        }
                    });
                })
                .catch(error => {
                    $store.state.serverPending--;
                    $store.state.serverFailed++;
                    reject(error)
                })
        });
    },
    setContentData(channel, content, wfAction, $store) {

        let DataProvider = this;
        return new Promise((resolve, reject) => {
            $store.state.serverPending++;
            var contentId = content.Id || content.id || content.ID;
            var fields = content.Parts || content;
            fetch(DataProvider.baseUrl + '/data/SaveContent?channelId=' + channel
                + (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '')
                + (contentId ? '&contentId=' + encodeURIComponent(contentId) : '')
                + (wfAction ? '&wfAction=' + encodeURIComponent(wfAction) : '')
                /*+ '&culture=' + culture*/, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    method: "POST",
                    body: Object.getOwnPropertyNames(fields).filter(k => fields[k]).map(k => 'contentFields[' + k.toLowerCase() + ']=' + encodeURIComponent(fields[k].Value !== undefined ? fields[k].Value : fields[k].Src !== undefined ? fields[k].Src : fields[k])).join('&')
                })
                .then(response => {
                    response.json().then((response) => {
                        //debugger;
                        /* Checking if error object was returned from the server */
                        if (!response) {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(null);
                        }
                        else {
                            $store.state.serverPending--;
                            $store.state.serverSuccess++;
                            resolve(response);
                        }
                    });
                })
                .catch(error => {
                    $store.state.serverPending--;
                    $store.state.serverFailed++;
                    reject(error)
                })
        });
    },
    getLastUpdate() {
        return fetch(this.baseUrl + '/data/lastupdate')
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {

                if (localStorage.getItem('lastUpdate')) {
                    let date = new Date(data.LastUpdate);
                    if ((new Date(localStorage.getItem('lastUpdate')) - date) < -5000) {
                        localStorage.setItem('lastUpdate', data.LastUpdate);
                        localStorage.setItem('updateNow', true);
                    }
                    else {
                        localStorage.setItem('updateNow', false);
                    }
                }
                else {
                    localStorage.setItem('lastUpdate', data.LastUpdate);
                    localStorage.setItem('updateNow', true);
                }
            });
    },
};
