<template>
    <div id="app">
        <div id="devLayer" v-if="devMode">
            <div class="template-editors" v-show="templateEdit">
                <h5 data-toggle="collapse" data-target="#editorTemplate">Template</h5>
                <div class="editor" id="editorTemplate">
                </div>
                <h5 data-toggle="collapse" data-target="#editorScript">JavaScript Module</h5>
                <div class="editor" id="editorScript">
                </div>
                <h5 data-toggle="collapse" data-target="#editorStyle">Style Sheet&nbsp;&nbsp;<small><input type="checkbox" id="scoped-check" :checked="scopedStyle" /><label for="scoped-check">&nbsp;Scoped</label></small></h5>
                <div class="editor" id="editorStyle">
                </div>
            </div>
            <button data-toggle="collapse" data-target="#devMenu">
                Development mode
                <i class="fa fa-chevron-down"></i>
            </button>
            <div class="collapse" id="devMenu">
                <ul role="menu">
                    <li>
                        Culture: {{$i18n.culture}}
                        &nbsp;
                        <button class="btn" @click="reloadPage()">
                            Reload page
                            <i class="fa fa-sync"></i>
                        </button>

                    </li>
                    <li class="divider"></li>
                    <li>
                        <strong>Client cache</strong> <a class="btn btn-small" @click="clearClientData(true)">Clear all <i class="fa fa-recycle"></i></a>
                    </li>
                    <li>
                        App config: {{appDataSize}} <a title="Ctrl+Alt+A" class="btn btn-small" @click="clearAppData(true)">Clear <i class="fa fa-recycle"></i></a>
                    </li>
                    <li>
                        Content data: {{contentDataSize}} <a title="Ctrl+Alt+C" class="btn btn-small" @click="clearContentData(true)">Clear <i class="fa fa-recycle"></i></a>
                    </li>
                    <li>
                        Templates: {{templatesSize}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a class="btn" style="right: 60px; " data-toggle="collapse" data-target="#templateMenu" title="List templates"><i class="fa fa-chevron-down"></i></a><a title="Ctrl+Alt+T" class="btn btn-small" @click="clearTemplates(true)">Clear <i class="fa fa-recycle"></i></a>
                        <ul id="templateMenu" class="collapse" style="width: 100%">
                            <li v-for="template in getTemplates()" :key="template.Name">
                                <i class="far fa-file-code"></i>&nbsp;
                                {{template.Name}}
                                <a title="Edit this template" class="btn btn-small" style="right: 30px;" @click="editTemplate(template)"><i class="fa fa-edit"></i></a>
                                <a title="Clear this template and reload" class="btn btn-small" @click="clearTemplate(template, true)"><i class="fa fa-recycle"></i></a>
                            </li>
                        </ul>
                    </li>
                    <li class="divider"></li>
                    <li><strong>Server requests</strong></li>
                    <li>
                        <span class="text-success">
                            <i class="fa fa-thumbs-up"></i>&nbsp;{{serverSuccess}}
                        </span>
                        <span class="text-warning">
                            <i class="fa fa-hourglass"></i>&nbsp;{{serverPending}}
                        </span>
                        <span class="text-danger">
                            <i class="fa fa-thumbs-down"></i>&nbsp;{{serverFailed}}
                        </span>
                        <button class="btn" @click="toggleRefreshServerCache">
                            Force refresh
                            <i v-if="refreshServerCache" class="fa fa-check-square"></i>
                            <i v-else class="fa fa-square"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <header id="adminHeader" v-if="adminMode">
            <!--<pre>{{config}}</pre>-->
        </header>
        <div :key="refreshKey" id="appContainer">
            <Layout v-if="config" :model="config.Layout" />
        </div>
        <div v-if="error">
            <block v-if="config" :template="config.LayoutContent.Parts.errortemplate.id" :block="error"></block>
            <div v-else>
                <h1>Oops..</h1>
                <h2>{{error.Error}}</h2>
                <p class="text-danger">{{error.Message}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    //require('./assets/Pace');

    import DataProvider from '../assets/DataProvider';
    import Layout from '../components/views/Layout.vue';

    import { mapState } from 'vuex'

    import { PNG } from 'pngjs/browser'
    import { jsPDF } from 'jspdf'
    window.PNG = PNG;
    window.jsPDF = jsPDF;

    export default {
        name: 'App',
        components: {
            Layout
        },
        rules: {
            'no-console': 'off'/*makeJSOnlyValue(`process.env.NODE_ENV === 'production' ? 'error' : 'off'`)*/,
            'no-debugger': 'off'/*makeJSOnlyValue(`process.env.NODE_ENV === 'production' ? 'error' : 'off'`)*/,
            'no-undef': 'off'
        },
        data() {
            return {
                config: null,
                dummyRecompute: '',
                refreshKey: 0,
                error: null,
                templateEdit: null,
                scopedStyle: false,
                paceFixedCounter: 0,
                config_ms: {}
            }
        },
        computed: {
            appDataSize() {
				return this.dummyRecompute + this.getSize(localStorage && localStorage.getItem('appData') || '')
            },
            templatesSize() {
				return this.dummyRecompute + this.getSize(localStorage && localStorage.getItem('templateCache') || '')
            },
            contentDataSize() {
				return this.dummyRecompute + this.getSize(localStorage && localStorage.getItem('dataCache') || '')
            },
            ...mapState([
                'devMode',
                'adminMode',
                'refreshServerCache',
                'serverPending',
                'serverSuccess',
                'serverFailed'
            ])
        },
        methods: {
            micrositesDetect(currentURL) {
                Object.keys(this.config.websites).every(key => {
                    /*let regex = new RegExp("^" + key + ".*");*/
                    let regex = new RegExp("^" + key + "(/|/.*)?$");

                    if (currentURL.match(regex)) {
                        //console.log(`This is the current website: ${key}`);
                        this.config.Layout = this.config.websites[key];
                        this.$store.commit('SET_CURRENTWEBSITE', key);
                        return false;
                    }

                    return true;
                });
            },
            getSize(variable) {
                var i = 0, fileSizeInBytes = variable.length;
                var byteUnits = [' B', ' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
                while (fileSizeInBytes > 1024) {
                    fileSizeInBytes = fileSizeInBytes / 1024;
                    i++;
                }

                return Math.max(fileSizeInBytes).toFixed(2) + byteUnits[i];
            },
            reloadPage() {
                document.location.reload(true);
            },
            startPaceFixed() {
                this.paceFixedCounter++;
                clearInterval(window.fixedpaceinterval);
                window.fixedpaceinterval = setInterval(() => {
                    document.body.classList.add('pace-running')
                    document.body.classList.remove('pace-done')
                    document.querySelector('.pace').classList.remove('pace-inactive')
                }, 5);
            },
            startPace() {
                clearInterval(window.paceinterval);
                window.paceinterval = setInterval(() => {
                    document.body.classList.add('pace-running')
                    document.body.classList.remove('pace-done')
                    document.querySelector('.pace').classList.remove('pace-inactive')
                }, 5);
            },
            stopPaceFixed() {
                this.paceFixedCounter--;
                if (!this.paceFixedCounter) {
                    clearInterval(window.fixedpaceinterval);
                    setTimeout(() => {
                        document.body.classList.remove('pace-running');
                        document.body.classList.add('pace-done');
                        document.querySelector('.pace').classList.add('pace-inactive');
                    }, 1500);
                }
            },
            stopPace() {
                clearInterval(window.paceinterval);
                document.body.classList.remove('pace-running');
                document.body.classList.add('pace-done');
                document.querySelector('.pace').classList.add('pace-inactive');
            },
            getTemplates() {
				return JSON.parse(localStorage.templateCache || "[]");
            },
            clearAppData(reload) {
                event.preventDefault()
                event.stopPropagation()
                this.$store.commit('CLEAR_APPDATA');
				window.localStorage.removeItem('appData');
                window.localStorage.removeItem('transcache_pt-PT');
                window.localStorage.removeItem('transcache_es-ES');
                window.localStorage.removeItem('transcache_en-GB');
                this.forceUpdate()
                reload && this.reloadPage()
            },
            clearTemplates(reload) {
                event.preventDefault()
                event.stopPropagation()
                this.$store.commit('CLEAR_TEMPLATES');
				window.localStorage.removeItem('templateCache');
                this.forceUpdate()
                reload && this.reloadPage()
            },
            clearTemplate(template, reload) {
                event.preventDefault()
                event.stopPropagation()
                let cache = this.getTemplates();
                delete cache[template.Id];
				window.localStorage.setItem('templateCache', JSON.stringify(cache));
                this.forceUpdate()
                reload && this.reloadPage()
            },
            editTemplate(template) {
                let $this = this;
                $this.templateEdit = template;
                ace.config.set('basePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.9/')
                $this.editorTemplate = ace.edit("editorTemplate")
                $this.editorTemplate.session.setMode("ace/mode/handlebars");
                var templateStr = /<template>(.*)<\/template>/gims.exec(template.Template)[1];
                $this.editorTemplate.setValue(templateStr);
                $this.editorScript = ace.edit("editorScript")
                $this.editorScript.session.setMode("ace/mode/javascript");
                var templateScript = /<script>(.*)<\/script>/gims.exec(template.Template)[1];
                $this.editorScript.setValue(templateScript);
                $this.editorStyle = ace.edit("editorStyle")
                $this.editorStyle.session.setMode("ace/mode/css");
                var templateStyleMatches = /<style\s*(scoped)?[^>]*>(.*)<\/style>/gims.exec(template.Template);
                var templateStyle = templateStyleMatches[2];
                $this.scopedStyle = templateStyleMatches[1] ? true : false;
                $this.editorStyle.setValue(templateStyle);

            },
            clearContentData(reload) {
                event.preventDefault()
                event.stopPropagation()
                this.$store.commit('CLEAR_DATACACHE');
                sessionStorage.removeItem('dataCache');
                this.forceUpdate()
                reload && this.reloadPage()
            },
            clearClientData(reload) {
                event.preventDefault()
                event.stopPropagation()
                this.clearAppData()
                this.clearTemplates()
                this.clearContentData()
                reload && this.reloadPage()
            },
            forceUpdate() {
                //debugger
                this.dummyRecompute = this.dummyRecompute.length ? '' : ' ';
            },
            toggleRefreshServerCache() {
                event.preventDefault()
                event.stopPropagation()
                this.$store.commit('TOGGLE_REFRESHSERVERCACHE')
            },
            adminParseNode(node) {
                var $app = this;
                while (/SCRBEGININFO/.test(node.innerHTML)) {
                    var m = /SCRBEGININFO([^,]*),([^,]*),([^,]*),([^,]*),([^,]*),([\s\S]*?)SCRENDINFO/ms.exec(node.innerHTML);

                    if (m)
                        node.innerHTML = node.innerHTML.replace(m[0], `<scradmin data-content="${m[1]}" data-channel="${m[2]}" data-field="${m[4]}" data-type="${m[5]}" >${m[6]}</scradmin>`);
                    //else
                    //    debugger
                }
                [...node.querySelectorAll('scradmin')].forEach(n => {
                    n.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        $app.adminStartEdit(n);
                    });
                });
            },
            adminStartEdit(node) {
                if (node.editor || node.getAttribute('contenteditable') == 'true')
                    return;

                var $app = this;
                switch (node.dataset.type) {
                    case 'html':
                        node.backup = node.innerHTML;
                        $app.$VueCkeditorEditors.classic.create(node).then(editor => {
                            node.editor = editor;
                        })
                            .catch(/*err => {
                                console.error(err.stack);
                            }*/);

                        break;
                    default:
                        node.backup = node.innerHTML;
                        node.setAttribute('contenteditable', 'true');
                        node.focus();
                        break;
                }
                var btCancel = document.createElement('button');
                //         btCancel.innerHTML = $app.$trans('Cancel');
                btCancel.classList.add('fa', 'fa-ban', 'text-danger');
                node.after(btCancel);
                var btSave = document.createElement('button');
                //        btSave.innerHTML = $app.$trans('Save');
                btSave.classList.add('fa', 'fa-save', 'text-success');
                node.after(btSave);
                btCancel.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    node.editor && node.editor.destroy();
                    node.setAttribute('contenteditable', 'false');
                    node.innerHTML = node.backup;
                    node.parentNode.removeChild(btSave);
                    node.parentNode.removeChild(btCancel);
                });
                btSave.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    var newValue = node.backup;
                    switch (node.dataset.type) {
                        case 'html':
                            newValue = node.editor.getData();
                            node.editor.destroy();
                            node.editor = undefined;
                            break;
                        default:
                            node.setAttribute('contenteditable', 'false');
                            newValue = node.innerHtml;
                            break;
                    }
                    let ct = { Id: node.dataset.content };
                    ct[node.dataset.field] = newValue;

                    node.parentNode.removeChild(btSave);
                    node.parentNode.removeChild(btCancel);

                    $app.$auth.$dataProvider.setContentData(node.dataset.channel, ct, null, $app.$store).then(/*(a) => {
                        console.log(a);
                    }*/);
                })
            }
        },
        beforeMount() {
            var $app = this;

            if (!window.localStorage.getItem('culture')) {
                window.localStorage.setItem('culture', this.$i18n.culture);
            }

            DataProvider.getLastUpdate().then(() => {
				if (localStorage.getItem('updateNow') == 'true') {
                    localStorage.setItem('updateNow', false);
                    //console.log('Updating now');

					this.$store.commit('CLEAR_APPDATA');
					window.localStorage.removeItem('appData');
					window.localStorage.removeItem('transcache_pt-PT');
					window.localStorage.removeItem('transcache_es-ES');
					window.localStorage.removeItem('transcache_en-GB');

					this.$store.commit('CLEAR_TEMPLATES');
					window.localStorage.removeItem('templateCache');

					this.$store.commit('CLEAR_DATACACHE');
					sessionStorage.removeItem('dataCache');
                    this.reloadPage();
				}
            });
            
            DataProvider.getConfig(this.$i18n.culture, this.$store).then(c => {

                // Pedro Gomes
                if (!!c.Microsites && c.Microsites.Error) {
                    c.Microsites = undefined;
                }

                if (!c.websites) { 
                    let config_ms = {};

                    if (!!c.Microsites) {
                        c.Microsites.forEach(microsite => {
                            if (!config_ms[`/${microsite.Parts.title.toLowerCase()}`]) {
                                config_ms[`/${microsite.Parts.title.toLowerCase()}`] = microsite;
                            }
                            else {
                                console.error('Duplicate microsite found.');
                            }
                        });
                    }

                    config_ms["/"] = c.Layout;

                    c["websites"] = config_ms;
                    c.Microsites = undefined;
                }

                $app.config = c;

                //console.log("Config:");
                //console.log(c);

                let culture = this.$i18n.culture;
                this.$store.commit('SET_APPDATA', { culture, data: c });

                this.micrositesDetect(window.location.pathname);

                $app.$auth.loginTemplate = c.Layout.Parts.loginTemplate;
                //read checkout url from scriptor
                $app.$auth.checkoutUrl = c.Layout.Parts.hostaddress.split('|').find(a => a.includes('checkout'));
                document.title = c.Layout.Title;
            }).catch(e => {
                $app.error = {
                    Error: $app.$trans('Error obtaining app configuration'), Message: e
                };
                $app.stopPace();
            });

            window.addEventListener('keydown', (ev) => {

                if ((ev.key == 'D' || ev.key == 'X') && (ev.ctrlKey || ev.altKey) && ev.shiftKey) {
                    ev.preventDefault();
                    $app.$store.commit('TOGGLE_DEVMODE');
                   
                }
                if ((ev.key == 'A' || ev.key == 'a') && (ev.ctrlKey && ev.altKey)) {
                    ev.preventDefault();
                    if ($app.devMode) {
                        $app.clearAppData(true);
                    }
                }
                if ((ev.key == 'T' || ev.key == 't') && (ev.ctrlKey && ev.altKey)) {
                    ev.preventDefault();
                    if ($app.devMode) {
                        $app.clearTemplates(true);
                    }
                }
                if ((ev.key == 'C' || ev.key == 'c') && (ev.ctrlKey && ev.altKey)) {
                    ev.preventDefault();
                    if ($app.devMode) {
                        $app.clearContentData(true);
                    }
                }
                if ((ev.key == 'A' || ev.key == 'X') && (ev.ctrlKey || ev.altKey) && ev.shiftKey) {
                    ev.preventDefault();
                    $app.$store.commit('TOGGLE_ADMINMODE');
                    document.location.reload();
                }
            });
        },
        mounted() {
            //test
            var $app = this;
            var oldFetch = window.fetch;  // must be on the global scope
            window.fetch = function (url, options, hideLoading) {
                if (!hideLoading) {
                    $app.startPaceFixed();
                }
                var promise = oldFetch(url, options);
                // Do something with the promise
                if (!hideLoading) {
                    promise.then(() => {
                        $app.stopPaceFixed();
                        if (window.finishedLoading) {
                            window.finishedLoading();
                        }
                    });
                }
                promise.catch(() => { $app.stopPaceFixed(); /*console.log("Failed to fetch " + url);*/ });
                return promise;
            }
            if ($app.adminMode) {
                $app.$nextTick(() => {
                    //debugger;
                    // Select the node that will be observed for mutations
                    const targetNode = document.getElementById('appContainer');

                    // Options for the observer (which mutations to observe)
                    const config = { attributes: false, childList: true, subtree: true, characterData: true };

                    // Callback function to execute when mutations are observed
                    const callback = function (mutationsList/*, observer*/) {
                        //debugger
                        // Use traditional 'for loops' for IE 11
                        for (let mutation of mutationsList) {
                            if (mutation.type === 'characterData') {
                                //debugger
                            } else if (mutation.type === 'childList' && mutation.addedNodes.length) {
                                //debugger;
                                //                                for (let node of mutation.addedNodes) {
                                if (/SCRBEGININFO/.test(document.body.innerText)) {
                                    $app.adminParseNode(document.body);
                                }
                                //                              }
                            }
                            //else if (mutation.type === 'attributes') {
                            //    debugger
                            //    console.log('The ' + mutation.attributeName + ' attribute was modified.');
                            //}
                        }
                    };

                    $app.adminParseNode(document.body)

                    // Create an observer instance linked to the callback function
                    const observer = new MutationObserver(callback);

                    // Start observing the target node for configured mutations
                    observer.observe(targetNode, config);

                });
            }

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if (urlParams.has("clearall") && urlParams.get("clearall") == 1) {
                this.toggleRefreshServerCache();
                this.clearClientData(true);
            }
        },
        watch: {
            $route(to, from) {
                if (this.config)
                    this.micrositesDetect(to.fullPath);
            }
        }
    };
</script>

<style>
    html, body {
        overflow: visible;
    }

    #devLayer {
        position: fixed;
        right: 0;
        z-index: 99999999999999;
    }

        #devLayer button {
            color: white;
            background: rgba(0,0,0,.5);
            border: 1px solid #000;
            line-height: 2.5;
            padding: 5px 10px 0;
            border-radius: 3px;
        }

    #devMenu {
        position: absolute;
        color: white;
        margin: 0;
        background: rgba(0,0,0,.5);
        border: 1px solid #000;
        line-height: 2.5;
        padding: 5px 65px 0 10px;
        border-radius: 3px;
        white-space: nowrap;
        right: 0 !important;
        left: auto !important;
        width: fit-content;
        transform: none !important
    }

    #devLayer .btn {
        position: absolute;
        color: white;
        background: rgba(0,0,0,.5);
        border: 1px solid #000;
        line-height: 1;
        padding: 3px;
        font-size: 12px;
        border-radius: 3px;
        right: 5px;
        margin-top: 10px;
    }

    #devLayer ul {
        list-style: none;
        padding: 0;
    }

    scradmin {
        cursor: url('data:@file/x-123;base64,AAACAAEAICAQAAMADwDoAgAAFgAAACgAAAAgAAAAQAAAAAEABAAAAAAAAAIAAAAAAAAAAAAAEAAAAAAAAAAAAAAA/wC7AP///wCAAF4AtbW1AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAABEQwAAAAAAAAAAAAAAAAAERBQwAAAAAAAAAAAAAAAAAANBQwAAAAAAAAAAAAAAAAAANBQwAAAAAAAAAAAAADMzMwNBQwAAAAAAAAAAAAAzMiIgNBQwAAAAAAAAAAAAMyMzMgNBQwAAAAAAAAAAADIzIjMgNBQwAAAAAAAAAAAyMiIjIwNBAAAAAAAAAAAAMjIiIyMAMAAAAAAAAAAAADIzIjMjAAAAAAAAAAAAAAAzIzMyMwAAAAAAAAAAAAAAMzIiIzMAAAAAAAAAAAAAADMzMzMzAAAAAAAAAAAAAAD/////////////////////////////////////////////////////////////////////////////////////4f///+D////gf///4D////Af///4D///AAf//wAD//8AAf//AAD//wAA//8AIf//ADP//wA///8AP///AD///w=='), default
    }

        scradmin[contenteditable=true] {
            display: block;
            outline: 1px dashed #ccc;
        }

            scradmin[contenteditable=true] + button {
                clear: left;
            }

                scradmin[contenteditable=true] + button + button {
                    clear: right;
                }

            scradmin[contenteditable=true] ~ button {
                display: block;
                float: left;
                background: transparent;
                width: 50%;
                border: 0;
                margin: 5px 0;
                padding: 3px 0;
                border-radius: 4px;
                opacity: 0.4;
            }

                scradmin[contenteditable=true] ~ button:hover {
                    opacity: 1;
                    background: #eee;
                }


    textarea {
        resize: vertical
    }

    .template-editors {
        height: calc(100vh);
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        position: fixed;
        z-index: 999999999000;
        left: 0;
        background: #dedede;
    }

    .editor {
        height: 100%
    }
</style>
