<template>
    <div>
        <!-- Dummy div to be replaced -->
        <div v-if="debug">
            <block v-if="appData[$i18n.culture] && appData[$i18n.culture].Layout && appData[$i18n.culture].Layout.Parts.errortemplate" :template="appData[$i18n.culture].Layout.Parts.errortemplate.Id" :block="model"></block>
            <div v-else>
                <h1>Oops..</h1>
                <h2>{{model.Error}}</h2>
                <p class="text-danger">{{model.Message}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    require('../../assets/Pace');
    import DataProvider from '../../assets/DataProvider';
    import ComponentBuilder from '../../assets/ComponentBuilder';

    import ArrayInput from '../forms/ArrayInput.vue';
    import ChannelItemsSelect from '../forms/ChannelItemsSelect.vue';
    import DateInputGroup from '../forms/DateInputGroup.vue';
    import HtmlInput from '../forms/HtmlInput.vue';
    import ImageDropTarget from '../forms/ImageDropTarget.vue';
    import PhoneNumberInput from '../forms/PhoneNumberInput.vue';

    import ChannelLink from '../helpers/ChannelLink.vue';
    import ContentLink from '../helpers/ContentLink.vue';
    import HeadSection from '../helpers/HeadSection.vue';

    import Block from '../views/Block.vue';
    import ChannelItems from '../views/ChannelItems.vue';
    import RoutedBlock from '../views/RoutedBlock.vue';

    import OpenLayersMap from '../maps/OpenLayers.vue';

    import { mapState } from 'vuex';


    import * as VuetifyComponents from 'vuetify/lib'


    export default {
        name: 'PageView',
        components: {
            ArrayInput,
            ChannelItemsSelect,
            DateInputGroup,
            HtmlInput,
            ImageDropTarget,
            PhoneNumberInput,

            ChannelLink,
            ContentLink,
HeadSection,
            HeadSection,

            Block,
            ChannelItems,
            RoutedBlock,
            OpenLayersMap,
            ...VuetifyComponents

        },
        computed: {
            ...mapState(['appData', 'currentWebsite'])
        },
        data() {
            return {
                debug: false,
                model: null,
                interval: null
            };
        },
        methods: {
            stopPace() {
                clearInterval(window.paceinterval);
                document.body.classList.remove('pace-running');
                document.body.classList.add('pace-done');
                document.querySelector('.pace').classList.add('pace-inactive');
            }
        },
        mounted() {
        },
        beforeMount() {
            var $page = this;
            var path = this.$route.path;
            var isContent = /-[0-9a-f]{8}$/i.test(path);

            /* Handle User Defined Redirects */
            let _path = path.indexOf("/") == 0 ? path.substring(1) : path;
            if (_path && this.appData && this.appData[this.$i18n.culture] && this.appData[this.$i18n.culture].RedirectList[_path.toLowerCase()]) {
                //this.$router.replace(this.appData[this.$i18n.culture].RedirectList[_path.toLowerCase()]);
                window.location.href = this.appData[this.$i18n.culture].RedirectList[_path.toLowerCase()];
            }
            /* Handle User Defined Redirects */

            if (isContent) {

                // Pedro Gomes: When the page is a content it is treated as an article.
                // Make sure to get look for the content in the articles sub-channel
                let articleSubChannelPath = this.$route.path.split('/');
                const lastPathElement = articleSubChannelPath[articleSubChannelPath.length - 1];
                articleSubChannelPath[articleSubChannelPath.length - 1] = `artigos___${articleSubChannelPath[articleSubChannelPath.length - 2]}`;
                articleSubChannelPath.push(lastPathElement);
                articleSubChannelPath = articleSubChannelPath.join('/');

                DataProvider.getContentData(this.$route.path, this.$i18n.culture, this.$store).then(d => {
                    if (d.Error) {
                        DataProvider.getContentData(articleSubChannelPath, this.$i18n.culture, this.$store).then(d2 => {
                            $page.model = d2;
                            var currentTitle = document.title.split(' :: ');
                            document.title = d2.Title + ' :: ' + currentTitle.pop();
                            DataProvider.getContentTemplate($page.model, $page.$store).then(t => {
                                // at this stage with have all data from server
                                $page.stopPace();
                                ComponentBuilder.buildPageComponent(d2, t, $page, {
                                    ArrayInput,
                                    ChannelItemsSelect,
                                    DateInputGroup,
                                    HtmlInput,
                                    ImageDropTarget,
                                    PhoneNumberInput,

                                    ChannelLink,
                                    ContentLink,
                                    HeadSection,

                                    Block,
                                    ChannelItems,
                                    RoutedBlock,
                                    OpenLayersMap,
                                    ...VuetifyComponents
                                });
                            })
                            .catch(e => {
                                $page.stopPace();
                                $page.debug = true;
                                $page.model = { Error: $page.$trans('Could not find template for the page you have requested'), Message: e };
                            });
                        })
                            .catch(e => {
                                $page.stopPace();
                                $page.debug = true;
                                $page.model = { Error: $page.$trans('Could not find the page you have requested'), Message: e };
                            });
                    }
                    else {
                        $page.model = d;
                        var currentTitle = document.title.split(' :: ');
                        document.title = d.Title + ' :: ' + currentTitle.pop();
                        DataProvider.getContentTemplate($page.model, $page.$store).then(t => {
                            // at this stage with have all data from server
                            $page.stopPace();
                            ComponentBuilder.buildPageComponent(d, t, $page, {
                                ArrayInput,
                                ChannelItemsSelect,
                                DateInputGroup,
                                HtmlInput,
                                ImageDropTarget,
                                PhoneNumberInput,

                                ChannelLink,
                                ContentLink,
                                HeadSection,

                                Block,
                                ChannelItems,
                                RoutedBlock,
                                OpenLayersMap,
                                ...VuetifyComponents
                            });
                        })
                        .catch(e => {
                            $page.stopPace();
                            $page.debug = true;
                            $page.model = { Error: $page.$trans('Could not find template for the page you have requested'), Message: e };
                        });
                    }
                }).catch(e => {

                    DataProvider.getContentData(articleSubChannelPath, this.$i18n.culture, this.$store).then(d2 => {
                        $page.model = d2;
                        var currentTitle = document.title.split(' :: ');
                        document.title = d2.Title + ' :: ' + currentTitle.pop();
                        DataProvider.getContentTemplate($page.model, $page.$store).then(t => {
                            // at this stage with have all data from server
                            $page.stopPace();
                            ComponentBuilder.buildPageComponent(d2, t, $page, {
                                ArrayInput,
                                ChannelItemsSelect,
                                DateInputGroup,
                                HtmlInput,
                                ImageDropTarget,
                                PhoneNumberInput,

                                ChannelLink,
                                ContentLink,
                                HeadSection,

                                Block,
                                ChannelItems,
                                RoutedBlock,
                                OpenLayersMap,
                                ...VuetifyComponents
                            });
                        })
                            .catch(e => {
                                $page.stopPace();
                                $page.debug = true;
                                $page.model = { Error: $page.$trans('Could not find template for the page you have requested'), Message: e };
                            });
                    })
                    .catch(e => {
                        $page.stopPace();
                        $page.debug = true;
                        $page.model = { Error: $page.$trans('Could not find the page you have requested'), Message: e };
                    });
                });
            }
            else if (this.appData[this.$i18n.culture]) {
                //Pedro Gomes
                let currWebiste = this.currentWebsite;
                if (!currWebiste) {
                    currWebiste = '/';
                }
                let homePath = this.appData[this.$i18n.culture].websites[currWebiste].ChannelPath.split('/');
                homePath[homePath.length - 1] = 'homepage';
                homePath = homePath.join('/');

                //console.log("Page.vue");
                //console.log(homePath);

                let appPath = $page.$route.path.replace(/\/*$/, '');
                appPath = appPath.split('/');

                if (appPath.length === 2) {
                    appPath[1] = '';
                }
                else {
                    appPath.shift();
                    appPath[0] = '';
                }

                appPath = appPath.join('/');
                appPath = appPath.replace(/\/*$/, '');

                //console.log($page.$route.path.replace(/\/*$/, ''));
                //console.log(appPath);

                DataProvider.getPropsData(/*this.appData[this.$i18n.culture].HomeChannel.Path*/ homePath + (homePath === "/homepage" ? $page.$route.path.replace(/\/*$/, '') : appPath), this.$i18n.culture, this.$store).then(d => {
                    $page.model = d;
                    var currentTitle = document.title.split(' :: ');
                    document.title = d.Title + ' :: ' + currentTitle.pop();
                    DataProvider.getContentTemplate($page.model, $page.$store).then(t => {
                        // at this stage with have all data from server
                        $page.stopPace();
                        ComponentBuilder.buildPageComponent(d, t, $page, {
                            ArrayInput,
                            ChannelItemsSelect,
                            DateInputGroup,
                            HtmlInput,
                            ImageDropTarget,
                            PhoneNumberInput,

                            ChannelLink,
                            ContentLink,
                            HeadSection,

                            Block,
                            ChannelItems,
                            RoutedBlock,
                            OpenLayersMap,
                            ...VuetifyComponents
                        });
                    })
                        .catch(e => {
                            $page.stopPace();
                            $page.debug = true;
                            $page.model = { Error: $page.$trans('Could not find template for the page you have requested'), Message: e };
                        });
                }).catch(e => {
                    $page.stopPace();
                    $page.debug = true;
                    $page.model = { Error: $page.$trans('Could not find the page you have requested'), Message: e };
                });
            }
            else {
                //this for the time that appData not loaded but the reload take a long time
                //should replace with better solution
                //window.location.reload();
            }
        }
    };
</script>
<style>
</style>
