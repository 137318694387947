import Vue from "vue";
import vuetify from '@/plugins/vuetify'
/*es-lint disable*/
export default {
    name: 'ComponentBuilder',
    buildBlockComponent(model, template, templateId, parent, components) {
        let scopeAttr = 'data-vt-' + templateId.split('-')[0];
        
        let moduleExt = {};
        var moduleExtStr = "";
        try {
            //moduleExt = eval('moduleExt = ' + /<script>(\s*export\s*default\s*)?(\{.*\})[^}]*<\/script>/gims.exec(template)[2]);
            moduleExtStr = /<script>(\s*export\s*default\s*)?(\{.*\})[^}]*<\/script>/gims.exec(template);
            if (!moduleExtStr) {
                moduleExt.template = '';
                console.error(`Error: Failed to find component module in template ${templateId}`);
            } else {
                moduleExtStr = moduleExtStr[2];
                moduleExt = eval('moduleExt = (function buildDynamicBlockComponent() { try { return ' + moduleExtStr + ' } catch(err) { return err; } })()');
                moduleExt.template = /<template>(.*)<\/template>/gims.exec(template);
                if (!moduleExt.template) {
                    moduleExt.template = '';
                    console.error(`Error: Failed to find main 'template' tag in template ${templateId}`);
                } else {
                    moduleExt.template = moduleExt.template[1];//.replace('>', ' ' + scopeAttr + '>');
                }
            }
        } catch (err) {
            moduleExt.template = '';
            console.error(`Error: ${err}`);
        }

        moduleExt.oldData = moduleExt.data || (() => {
            return {};
        });
        //debugger
        moduleExt.data = () => {
            let oldData = moduleExt.oldData()
            //debugger
            oldData.model = model;
            oldData.Model = model;
            oldData.parts = model.Parts;
            oldData.Parts = model.Parts;
            return oldData;
        };

        let warning = null;

        if (/[a-z]{2}_[a-z]{2}__/.test(moduleExt.template)) {
            warning = "Use of field(s) in localized version(s): " +
                /[a-z]{2}_[a-z]{2}__[a-z|_]*/gims.exec(moduleExt.template).join(', ');

            moduleExt.template = moduleExt.template.replace(/[a-z]{2}_[a-z]{2}__/gims, '');
        }

        if (components)
            moduleExt.components = components;

        moduleExt.name = 'block-' + model.Id;

        var PageComponent = Vue.extend(moduleExt);
        var pageInstance = new PageComponent({
            router: parent.$router,
            store: parent.$store,
            vuetify
        });

        let overriden = Vue.config.errorHandler;

        Vue.config.errorHandler = (err) => {
            //debugger
            console.error(err.toString())
        };

        pageInstance.$mount()    // pass nothing

        //parent.$el
        //    .innerHTML = pageInstance.$el.outerHTML; 
        if (!parent.$el.querySelector('.error'))
            parent.$el.replaceWith(pageInstance.$el);

        parent.$el = pageInstance.$el;

        //while (parent.$el.attributes.length > 0)
        //    parent.$el.removeAttribute(parent.$el.attributes[0].name);

        parent.$el.setAttribute('data-vt-block', '');
        parent.$el.setAttribute(scopeAttr, '');
        parent.$el.model = model;

        Vue.config.errorHandler = overriden;

        if (warning) {
            console.info(`Warning: ${warning}`);
        }
            

        let fullCss = /<style>(.*)<\/style>/.test(template) ? /<style>(.*)<\/style>/gim.exec(template)[1] : null;
        let scopedCss = /<style +scoped[^>]*>(.*)<\/style>/gim.test(template) ? /<style +scoped[^>]*>(.*)<\/style>/gim.exec(template)[1] : null;

        if (!document.querySelector('[data-template="' + templateId + '"]')) {

            if (fullCss) {
                let css = document.createElement('style');
                css.innerText = fullCss;
                css.setAttribute("data-template", templateId)
                document.head.appendChild(css);
            }

            if (scopedCss) {
                //debugger
                let cssElement = document.createElement('style');
                let scope = '[' + scopeAttr + ']';

                let css = require('css');


                var parsedCss = css.parse(scopedCss), selector;
                for (let i = 0; i < parsedCss.stylesheet.rules.length; i++) {
                    if (parsedCss.stylesheet.rules[i].type == 'rule')
                        for (let j = 0; j < parsedCss.stylesheet.rules[i].selectors.length; j++) {
                            selector = parsedCss.stylesheet.rules[i].selectors[j];

                            if (/^[a-zA-Z.]/.test(selector)) {
                                parsedCss.stylesheet.rules[i].selectors[j] = selector.replace(/^([a-zA-Z.0-9_-]*)/g, "$1" + scope);
                            } else {
                                parsedCss.stylesheet.rules[i].selectors[j] = scope + selector;
                            }

                            parsedCss.stylesheet.rules[i].selectors[j] += ', ' + scope + ' ' + selector;
                        }
                    if (parsedCss.stylesheet.rules[i].type == 'media')
                        for (let j = 0; j < parsedCss.stylesheet.rules[i].rules.length; j++) {
                            for (let k = 0; k < parsedCss.stylesheet.rules[i].rules[j].selectors.length; k++) {
                                selector = parsedCss.stylesheet.rules[i].rules[j].selectors[k];

                                if (/^[a-zA-Z.]/.test(selector)) {
                                    parsedCss.stylesheet.rules[i].rules[j].selectors[k] = selector.replace(/^([a-zA-Z.0-9_-]*)/g, "$1" + scope);
                                } else {
                                    parsedCss.stylesheet.rules[i].rules[j].selectors[k] = scope + selector;
                                }

                                parsedCss.stylesheet.rules[i].rules[j].selectors[k] += ', ' + scope + ' ' + selector;
                            }

                        }
                }
                //parsedCss.stylesheet.rules.forEach(r => r.selectors && r.selectors.forEach(s => s = scope + s));
                scopedCss = css.stringify(parsedCss); // scopedCss.replace(/(\s*)([^,{]+[,{])/gmi, "$1" + scope + " $2");
                cssElement.innerHTML = scopedCss;
                cssElement.setAttribute("data-template", templateId);
                document.head.appendChild(cssElement);
            }
        }
        return pageInstance;
    },
    buildPageComponent(modelIn, template, parent, components) {
        //debugger;
        let scopeAttr = 'data-vt-' + modelIn.Template.Id.split('-')[0];
        let partsProxy = {};
        //debugger;
        //for (var k in modelIn.Parts) {
        //    if (typeof (modelIn.Parts[k]) === 'string')
        //        partsProxy[k] = '__scrsrc__'+k+'__ id="dasds">' + modelIn.Parts[k] + '</scriptor>';
        //}

        var model = {
            ...modelIn, Parts: { ...modelIn.Parts, ...partsProxy }
        };
        let moduleExt = {};
        var moduleExtStr = "";
        try {
            //moduleExt = eval('moduleExt = ' + /<script>(\s*export\s*default\s*)?(\{.*\})[^}]*<\/script>/gims.exec(template)[2]);
            moduleExtStr = /<script>(\s*export\s*default\s*)?(\{.*\})[^}]*<\/script>/gims.exec(template);
            if (!moduleExtStr) {
                moduleExt.template = '<template></template>';
                console.error(`Error: Failed to find component module in template ${templateId}`);
            } else {
                moduleExtStr = moduleExtStr[2];
                moduleExt = eval('moduleExt = (function buildDynamicBlockComponent() { try { return ' + moduleExtStr + ' } catch(err) { return err; } })()');
                moduleExt.template = /<template>(.*)<\/template>/gims.exec(template);
                if (!moduleExt.template) {
                    moduleExt.template = '<template></template>';
                    console.error(`Error: Failed to find main 'template' tag in template ${templateId}`);
                } else {
                    moduleExt.template = moduleExt.template[1];//.replace('>', ' ' + scopeAttr + '>');
                }
            }
        } catch (err) {
            //console.log(err);
            var errPos = parseInt(err.stack.replace(/.*:([0-9]*):([0-9]*)\).*/gims, '$2'));
            moduleExt.template = '<template></template >';
            console.error(`${err} \n ${moduleExtStr.substr(errPos - 30, 60)}`);
        }

        //debugger
        moduleExt.oldData = moduleExt.data || (() => {
            return {};
        });
        //debugger
        moduleExt.data = () => {
            let oldData = moduleExt.oldData()
            //debugger
            oldData.model = model;
            oldData.Model = model;
            oldData.parts = model.Parts;
            oldData.Parts = model.Parts;
            return oldData;
        };

        let warning = null;
        //debugger
        if (/[a-z]{2}_[a-z]{2}__/.test(moduleExt.template)) {
            //debugger;
            warning = "Use of field(s) in localized version(s): " +
                /[a-z]{2}_[a-z]{2}__[a-z|_]*/gims.exec(moduleExt.template).join(', ');

            moduleExt.template = moduleExt.template.replace(/[a-z]{2}_[a-z]{2}__/gims, '');
        }

        if (components)
            moduleExt.components = components;

        moduleExt.name = 'block-' + model.Id;

        var PageComponent = Vue.extend(moduleExt);
        var pageInstance = new PageComponent({
            router: parent.$router,
            store: parent.$store,
            vuetify
        });

        let overriden = Vue.config.errorHandler;

        Vue.config.errorHandler = (err) => {
            //debugger
            parent.$el.innerHTML = '';
            console.error(err.toString());
        };

        pageInstance.$mount()    // pass nothing

        parent.$el.appendChild(pageInstance.$el)

        while (parent.$el.attributes.length > 0)
            parent.$el.removeAttribute(parent.$el.attributes[0].name);

        parent.$el.setAttribute('data-vt-page', '');
        parent.$el.setAttribute(scopeAttr, '');
        parent.$el.model = model;

        Vue.config.errorHandler = overriden;

        if (warning) { 
            console.info(`Warning: ${warning}`);
        }

        let fullCss = /<style>(.*)<\/style>/.test(template) ? /<style>(.*)<\/style>/gim.exec(template)[1] : null;
        let scopedCss = /<style +scoped[^>]*>(.*)<\/style>/gim.test(template) ? /<style +scoped[^>]*>(.*)<\/style>/gim.exec(template)[1] : null;

        if (!document.querySelector('[data-template="' + model.Template.Id + '"]')) {

            if (fullCss) {
                let css = document.createElement('style');
                css.innerText = fullCss;
                css.setAttribute("data-template", model.Template.Id)
                document.head.appendChild(css);
            }

            if (scopedCss) {
                //debugger
                let cssElement = document.createElement('style');
                let scope = '[' + scopeAttr + '] ';

                let css = require('css');


                var parsedCss = css.parse(scopedCss), selector;
                for (let i = 0; i < parsedCss.stylesheet.rules.length; i++) {
                    if (parsedCss.stylesheet.rules[i].type == 'rule')
                        for (let j = 0; j < parsedCss.stylesheet.rules[i].selectors.length; j++) {
                            selector = parsedCss.stylesheet.rules[i].selectors[j];

                            if (/^[a-zA-Z.]/.test(selector)) {
                                parsedCss.stylesheet.rules[i].selectors[j] = selector.replace(/^([a-zA-Z.0-9_-]*)/g, "$1" + scope);
                            } else {
                                parsedCss.stylesheet.rules[i].selectors[j] = scope + selector;
                            }

                            parsedCss.stylesheet.rules[i].selectors[j] += ', ' + scope + ' ' + selector;
                        }
                    if (parsedCss.stylesheet.rules[i].type == 'media')
                        for (let j = 0; j < parsedCss.stylesheet.rules[i].rules.length; j++) {
                            for (let k = 0; k < parsedCss.stylesheet.rules[i].rules[j].selectors.length; k++) {
                                selector = parsedCss.stylesheet.rules[i].rules[j].selectors[k];

                                if (/^[a-zA-Z.]/.test(selector)) {
                                    parsedCss.stylesheet.rules[i].rules[j].selectors[k] = selector.replace(/^([a-zA-Z.0-9_-]*)/g, "$1" + scope);
                                } else {
                                    parsedCss.stylesheet.rules[i].rules[j].selectors[k] = scope + selector;
                                }

                                parsedCss.stylesheet.rules[i].rules[j].selectors[k] += ', ' + scope + ' ' + selector;
                            }

                        }
                }
                //parsedCss.stylesheet.rules.forEach(r => r.selectors && r.selectors.forEach(s => s = scope + s));
                scopedCss = css.stringify(parsedCss); // scopedCss.replace(/(\s*)([^,{]+[,{])/gmi, "$1" + scope + " $2");
                cssElement.innerHTML = scopedCss;
                cssElement.setAttribute("data-template", model.Template.Id);
                document.head.appendChild(cssElement);
            }
        }
    },
    buildPanelComponent(model, template, templateId, parent, components) {
        let scopeAttr = 'data-vt-' + templateId.split('-')[0];

        let moduleExt = {};
        var moduleExtStr = "";
        try {
            //moduleExt = eval('moduleExt = ' + /<script>(\s*export\s*default\s*)?(\{.*\})[^}]*<\/script>/gims.exec(template)[2]);
            moduleExtStr = /<script>(\s*export\s*default\s*)?(\{.*\})[^}]*<\/script>/gims.exec(template);
            if (!moduleExtStr) {
                moduleExt.template = '';
                console.error(`Error: Failed to find component module in template ${templateId}`);
            } else {
                moduleExtStr = moduleExtStr[2];
                moduleExt = eval('moduleExt = (function buildDynamicBlockComponent() { try { return ' + moduleExtStr + ' } catch(err) { return err; } })()');
                moduleExt.template = /<template>(.*)<\/template>/gims.exec(template);
                if (!moduleExt.template) {
                    moduleExt.template = '';
                    console.error(`Error: Failed to find main 'template' tag in template ${templateId}`);
                } else {
                    moduleExt.template = moduleExt.template[1];//.replace('>', ' ' + scopeAttr + '>');
                }
            }
        } catch (err) {
            var errPos = parseInt(err.stack.replace(/.*<anonymous>:([0-9]*):([0-9]*)\).*/gims, '$2'));
            moduleExt.template = '';
            console.error(`Error: ${err}\n ${moduleExtStr.substr(errPos - 30, 60)}`);
        }

        moduleExt.oldData = moduleExt.data || (() => {
            return {};
        });
        //debugger
        moduleExt.data = () => {
            let oldData = moduleExt.oldData()
            //debugger
            oldData.model = model;
            oldData.Model = model;
            oldData.parts = model.Parts;
            oldData.Parts = model.Parts;
            return oldData;
        };

        let warning = null;

        if (/[a-z]{2}_[a-z]{2}__/.test(moduleExt.template)) {
            warning = "Use of field(s) in localized version(s): " +
                /[a-z]{2}_[a-z]{2}__[a-z|_]*/gims.exec(moduleExt.template).join(', ');

            moduleExt.template = moduleExt.template.replace(/[a-z]{2}_[a-z]{2}__/gims, '');
        }

        if (components)
            moduleExt.components = components;

        moduleExt.name = 'block-' + model.Id;

        var PageComponent = Vue.extend(moduleExt);
        var pageInstance = new PageComponent({
            router: parent.$router,
            store: parent.$store
        });

        let overriden = Vue.config.errorHandler;

        Vue.config.errorHandler = (err) => {
            //debugger
            let pre = document.createElement('pre');
            pre.classList.add('error', 'text-danger');
            pre.innerText = err.stack;
            var errPos = parseInt(err.stack.replace(/.*<anonymous>:([0-9]*):([0-9]*)\).*/gims, '$2'));
            pre.innerText += '\n' + moduleExtStr.substr(errPos - 30, 60) + '\n'
                + '------------------------------^';
            parent.$el.firstChild.replaceWith(pre);
        };

        pageInstance.$mount();    // pass nothing

        //parent.$el
        //    .innerHTML = pageInstance.$el.outerHTML; 

        if (!parent.$el.querySelector('.error'))
            parent.$el.firstChild.replaceWith(pageInstance.$el);
        else
            parent.$el = pageInstance.$el;

        //while (parent.$el.attributes.length > 0)
        //    parent.$el.removeAttribute(parent.$el.attributes[0].name);

        parent.$el.setAttribute('data-vt-panel', '');
        parent.$el.setAttribute(scopeAttr, '');
        parent.$el.model = model;

        Vue.config.errorHandler = overriden;

        if (warning) {
            console.info(`Warning: ${warning}`);
        }

        let fullCss = /<style>(.*)<\/style>/.test(template) ? /<style>(.*)<\/style>/gim.exec(template)[1] : null;
        let scopedCss = /<style +scoped[^>]*>(.*)<\/style>/gim.test(template) ? /<style +scoped[^>]*>(.*)<\/style>/gim.exec(template)[1] : null;

        if (!document.querySelector('[data-template="' + templateId + '"]')) {

            if (fullCss) {
                let css = document.createElement('style');
                css.innerText = fullCss;
                css.setAttribute("data-template", templateId)
                document.head.appendChild(css);
            }

            if (scopedCss) {
                //debugger
                let cssElement = document.createElement('style');
                let scope = '[' + scopeAttr + ']';

                let css = require('css');


                var parsedCss = css.parse(scopedCss), selector;
                for (let i = 0; i < parsedCss.stylesheet.rules.length; i++) {
                    if (parsedCss.stylesheet.rules[i].type == 'rule')
                        for (let j = 0; j < parsedCss.stylesheet.rules[i].selectors.length; j++) {
                            selector = parsedCss.stylesheet.rules[i].selectors[j];

                            if (/^[a-zA-Z.]/.test(selector)) {
                                parsedCss.stylesheet.rules[i].selectors[j] = selector.replace(/^([a-zA-Z.0-9_-]*)/g, "$1" + scope);
                            } else {
                                parsedCss.stylesheet.rules[i].selectors[j] = scope + selector;
                            }

                            parsedCss.stylesheet.rules[i].selectors[j] += ', ' + scope + ' ' + selector;
                        }
                    if (parsedCss.stylesheet.rules[i].type == 'media')
                        for (let j = 0; j < parsedCss.stylesheet.rules[i].rules.length; j++) {
                            for (let k = 0; k < parsedCss.stylesheet.rules[i].rules[j].selectors.length; k++) {
                                selector = parsedCss.stylesheet.rules[i].rules[j].selectors[k];

                                if (/^[a-zA-Z.]/.test(selector)) {
                                    parsedCss.stylesheet.rules[i].rules[j].selectors[k] = selector.replace(/^([a-zA-Z.0-9_-]*)/g, "$1" + scope);
                                } else {
                                    parsedCss.stylesheet.rules[i].rules[j].selectors[k] = scope + selector;
                                }

                                parsedCss.stylesheet.rules[i].rules[j].selectors[k] += ', ' + scope + ' ' + selector;
                            }

                        }
                }
                //parsedCss.stylesheet.rules.forEach(r => r.selectors && r.selectors.forEach(s => s = scope + s));
                scopedCss = css.stringify(parsedCss); // scopedCss.replace(/(\s*)([^,{]+[,{])/gmi, "$1" + scope + " $2");
                cssElement.innerHTML = scopedCss;
                cssElement.setAttribute("data-template", templateId);
                document.head.appendChild(cssElement);
            }
        }
    },
};