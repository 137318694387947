<template>
    <router-link :to="path">
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        name: 'content-link',
        props: ['content', 'query'],
        data() {
            return {
                path: ''
            };
        },
        beforeMount() {
            this.path = this.content.Path.replace(/^.*\/homepage/, '').split('_').join('_') + '-' + this.content.Id.split('-')[0] + (this.query ? `?${this.query}`: '');

            // Pedro Gomes: Removes the article sub-channel from the path
            if (!window.localStorage.getItem("resolvePath")) {
                this.path = this.path.split('/');
                this.path[this.path.length - 2] = this.path[this.path.length - 1];
                this.path.pop();
                this.path = this.path.join('/');
            }
            
        }
    }
</script>

<style scoped>
</style>