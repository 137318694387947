<template>
    <div class="image-drop-target">
        <input type="file" @change="changeImage($event)" />
        <img v-if="src && (src.indexOf('png') > -1 || src.indexOf('jpg') > -1 || src.indexOf('jpeg') > -1 || src.indexOf('gif') > -1)" :src="src" />
        <i v-else-if="value" :class="(iconClass || 'fa fa-image') +' pull-left'"></i>
        <slot name="helper" :slot-key="'image-drop-helper'"></slot>
        <small v-if="valueType == 'object'">{{value.Filename}}<br />{{fileSize(value.Size)}}</small>
        <label class="pull-left">{{placeholder}}</label>
        <button v-if="remove && value" class="btn btn-primary" @click="clearImage()"><i class="fa fa-trash"></i></button>
    </div>
</template>
<script>
    /*eslint-disable */

    export default {
        name: 'ImageDropTarget',
        props: {
            iconClass: { default: 'fa fa-file' },
            value: { default: { Src: '' } },
            placeholder: { type: String, default: '' },
            accept: { type: String, default: '' },
            remove: { type: Boolean, default: true },
            maxsize: { type: Number, default: 30 * 1024 * 1024 }
        },
        data() {
            return {
                valueType: typeof (this.value || { Src: '' }),
                valueModel: this.value || { Src: '' },
                src: typeof(this.value) == 'object' ? this.value.Src : this.value || ''
            }
        },
        methods: {
            fileSize(size) {
                var suf = [' B', ' kB', ' MB', ' GB'];
                var sufI = 0;
                var s = size;
                while (s > 1024) {
                    sufI++;
                    s /= 1024;
                }
                return (Math.round(s * 100) / 100) + suf[sufI];
            },
            changeImage($event) {
                var file = $event.target.files[0];
                if (file.size > this.maxsize) {
                    this.$emit('error', 'File too big');
                    this.$el.classList.add('bg-danger');
                    this.$el.style.transition = '1s all linear';
                    setTimeout(() => {
                        this.$el.classList.remove('bg-danger');
                    }, 1000);
                    $event.preventDefault();
                    return false;
                }
                if (this.accept && !new RegExp(this.accept, 'gi').test(file.name)) {
                    this.$emit('error', 'File invalid');
                    this.$el.classList.add('bg-danger');
                    this.$el.style.transition = '1s all linear';
                    setTimeout(() => {
                        this.$el.classList.remove('bg-danger');
                    }, 1000);
                    $event.preventDefault();
                    return false;
                }
                var reader = new FileReader();
                var that = this;
                reader.onloadend = function () {
                    that.src = reader.result;
                    that.updateValue(
                        that.valueType === 'string' ?
                            reader.result : {
                                Src: reader.result,
                                Filename: file.name,
                                Size: file.size
                            });
                }

                if (file) {
                    reader.readAsDataURL(file);
                } else {
                    this.updateValue('');
                }
            },
            clearImage() {
                event.preventDefault()
                if (confirm('Sure?'))
                    this.updateValue('')
            },
            updateValue(value) {
                this.valueModel = value
                this.$emit('input', value)
                this.$emit('update', value)
                this.$emit('change', value)
                this.$el.classList.add('bg-success');
                this.$el.style.transition = '1s all linear';
                setTimeout(() => {
                    this.$el.classList.remove('bg-success');
                }, 1000);
            }
        },
        mounted() {
        }
    }
</script>
<style scoped>
    .image-drop-target {
        position: relative;
        text-align: right;
        vertical-align: middle;
        line-height: 1.9;
        height: fit-content;
    }

        .image-drop-target img {
            max-height: 80px;
            float: left
        }
        .image-drop-target i {
            font-size: 60px;
            float: left;
            color: #ddd;
        }

        .image-drop-target input {
            opacity: 0;
            position: absolute;
            z-index: 1000;
            display: block;
            width: 100%;
            height: 100%;
            cursor: default;
            left: 0;
            top: 0;
        }

        .image-drop-target button {
            z-index: 1001;
            padding: .5em;
            border-radius: 2px;
            bottom: 0;
            position: absolute;
            right: 0;
            opacity: 0;
            transition: opacity 2s;
        }

        .image-drop-target:hover button {
            opacity: 1;
        }
</style>