export default {
    callingAPI: false,
    searching: '',
    serverURI: 'http://10.110.1.136:8080',
    user: null,
    token: null,
    websiteInfo: null,
    saveData: false,
    userInfo: sessionStorage && sessionStorage.getItem('userInfo') && JSON.parse(sessionStorage.getItem('userInfo')) || null,
    registrationProgress: {
        profile: 0,
        checklist: 0,
        declaration: 0,
        payment: 0
    },
    currentWebsite: null,
    appData: localStorage && localStorage.getItem('appData') && JSON.parse(localStorage.getItem('appData')) || null,
    devMode: sessionStorage && sessionStorage.getItem('devMode') && JSON.parse(sessionStorage.getItem('devMode')) || false,
    adminMode: sessionStorage && sessionStorage.getItem('adminMode') && JSON.parse(sessionStorage.getItem('adminMode')) || false,
    dataCache: sessionStorage && sessionStorage.getItem('dataCache') && JSON.parse(sessionStorage.getItem('dataCache')) || {},
    templateCache: localStorage && localStorage.getItem('templateCache') && JSON.parse(localStorage.getItem('templateCache')) || {},
    refreshServerCache: sessionStorage && sessionStorage.getItem('refreshServerCache') && JSON.parse(sessionStorage.getItem('refreshServerCache')) || false,
    serverPending: 0,
    serverSuccess: 0,
    serverFailed: 0,
    reloadKey: 0
}
