<template>
    <router-link :to="path">
        <slot></slot>
    </router-link>
</template>

<script>
    export default {
        name: 'channel-link',
        props: ['channel', 'query'],
        data() {
            return {
                path: ''
            };
        },
        beforeMount() {
            this.path = this.channel.Path.replace(/^.*\/homepage/, '').replace(/\/props_.*$/, '').split('_').join('-') + '/' + (this.query ? `?${this.query}` : '');
        }
    }
</script>

<style scoped>
</style>