import Vue from 'vue'
import VueRouter from 'vue-router'

// Register plugin
Vue.use(VueRouter)

import routes from './routes/GenericRoutes'

// Routing logic
var router = new VueRouter({
    routes: routes,
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior: function (to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    }
})

// Some middleware to help us ensure the user is authenticated.
// eslint-disable-next-line
router.beforeEach((to, from, next) => {

    if (router.app.$auth.token) {

        // this route requires auth, check if logged in
        // if not, redirect to login page.
        router.app.$auth.updateToken(
            () => {
                clearInterval(window.paceinterval);
                window.paceinterval = setInterval(() => {
                    document.body.classList.add('pace-running')
                    document.body.classList.remove('pace-done')
                    document.querySelector('.pace').classList.remove('pace-inactive')
                }, 5);

                next()
            },
            () => {
                if (to.fullPath != "/login")
                    router.app.$auth.redirectTo = to.fullPath;
                next({
                    path: '/login'
                })
            }
        );
    }
    else {
        clearInterval(window.paceinterval);
        window.paceinterval = setInterval(() => {
            document.body.classList.add('pace-running')
            document.body.classList.remove('pace-done')
            document.querySelector('.pace').classList.remove('pace-inactive')
        }, 5);

        next()
    }
})

// Support for google analytics
// eslint-disable-next-line
router.afterEach((to, from) => {
    if (window.ga) {
        window.ga('set', 'page', to.path);
        window.ga('send', 'pageview');
    }
})

import { sync } from 'vuex-router-sync'
import store from './store'

sync(store, router);

// Import Helpers for filters
import { domain, count, prettyDate, pluralize } from './filters'

// Install and register helper items
Vue.filter('count', count)
Vue.filter('domain', domain)
Vue.filter('prettyDate', prettyDate)
Vue.filter('pluralize', pluralize)


import Auth from './assets/AuthProvider'

// Install Auth plugin
Vue.use(Auth, { store, router });

import I18N from './assets/I18nProvider'

// install multi-culture plugin
Vue.use(I18N);

import VCalendar from 'v-calendar';

// Install calendar plugin
Vue.use(VCalendar);

//import CKEditor from '@ckeditor/ckeditor5-vue';

//import ClassicEditor from 'ckeditor5-build-classic-mathtype';
//store.CKEditor = ClassicEditor;
import ClassicEditor from 'ckeditor5-build-classic-mathtype'
import VueCkeditor from 'vue-ckeditor5'

Vue.use(VueCkeditor.plugin, {
    editors: {
        classic: ClassicEditor,
    },
    name: 'ckeditor'
});

// Install CKEditor 5 plugin
//Vue.use(CKEditor);

import * as Plugins from './plugins/config-plugin.js'
Vue.use(Plugins.Config.install)

import ModularApp from './apps/ModularApp.vue';

new Vue({
    el: '#app',
    router: router,
    store: store,
    render: h => h(ModularApp)
}).$mount('#app');