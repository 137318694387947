<template>
    <div class="channel-items-wrapper">
        <slot name="loading" v-if="loading" slot-key="'channel-items-loading'"></slot>
        <table class="channel-items" v-if="tag == 'table'">
            <thead><slot name="thead" slot-key="'channel-items-thead'"></slot></thead>
            <tbody>
                <template v-for="(item, index) in currentPage">
                    <slot :slot-key="'channel-items-slot-' + item.Id" :index="index + start" :item="item"></slot>
                </template>
            </tbody>
            <tfoot><slot name="tfoot" slot-key="'channel-items-tfoot'"></slot></tfoot>
        </table>
        <div class="channel-items" v-else>
            <template v-for="(item, index) in currentPage">
                <slot :slot-key="'channel-items-slot-' + item.Id" :index="index + start" :item="item"></slot>
            </template>
        </div>
        <slot name="no-results" v-if="!loading && currentPage.length == 0" :slot-key="'channel-items-no-results'"></slot>
    </div>
</template>

<script>
    import DataProvider from '../../assets/DataProvider';
    import RestDataProvider from '../../assets/RestDataProvider';
    //import ComponentProvider from '../../assets/ComponentProvider';

    export default {
        name: 'channel-items',
        props: ['channel', 'start', 'max', 'sort', 'search', 'force', 'abort', 'anonymous', 'tag', 'backoffice'],
        data() {
            return {
                currentPage: [],
                loading: false
            };
        },
        beforeMount() {
            this.update();
        },
        methods: {
            update() {
                var $ci = this;
                this.loading = true;
                (this.backoffice ? RestDataProvider : DataProvider).getChannelItems(this.channel, this.start, this.max, this.sort, this.search, this.$i18n.culture, this.force ? null : this.$store, this.abort, this.anonymous)
                    .then(r => {
                        $ci.currentPage = r;
                        $ci.loading = false;
                        $ci.$emit('list-update');
                    });
            }
        }
    }
</script>

<style scoped>
</style>