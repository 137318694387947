<template>
    <div v-show="1" class="panel-container" id="map-container">
        <div id="map" :class="perspectiveOn ? 'perspective' : ''"></div>
        <div class="btn-group dropleft float-right ol-control btn-right" id="btn-options">
            <button class="" type="button" data-toggle="dropdown">
                <i class="material-icons-outlined">tune</i>
            </button>
            <div class="dropdown-menu" @click.prevent.stop="">
                <form class="btn-group">
                    <button v-show=0 @click.prevent.stop="perspectiveOn = !perspectiveOn" id="btn-perspective">
                        <i class="material-icons-outlined md-16" :style="{color: perspectiveOn ? 'royalblue' : 'gray'}">3d_rotation</i>
                    </button>
                    <button @click.prevent.stop="satelliteOn = !satelliteOn; updateBase()" id="btn-changebase">
                        <i class="material-icons-outlined md-16"
                           :style="{color: satelliteOn ? 'royalblue' : 'gray'}">wallpaper</i>
                    </button>
                    <button @click.prevent.stop="nightModeOn = 1 - nightModeOn; updateBase()" id="btn-nightmode">
                        <i class="material-icons-outlined md-16"
                           :style="{color: nightModeOn ? 'royalblue' : 'gray'}">nights_stay</i>
                    </button>
                    <span class="submenu">
                        <button id="btn-layers" @click.prevent.stop="toggleSubmenu">
                            <i class="material-icons-outlined md-16">layers</i>
                        </button>
                        <span class="off" id="layers-menu">
                            <a @click.prevent.stop="toggleLayer(iLayer)" v-for="(layer, iLayer) in mapLayers" :key="`layer-toggle-${iLayer}`">
                                <span class="material-icons-outlined">
                                    {{layer.i}}
                                </span>
                                {{layer.t}}
                                <i class="material-icons-outlined">check_box{{layer.v ? '' : '_outline_blank'}}</i>
                            </a>
                        </span>
                    </span>
                </form>
            </div>
        </div>
        <div class="btn-group ol-control btn-right" id="btn-tracking">
            <button @click.prevent="toggleTracking">
                <i class="material-icons-outlined md-16" v-if="trackingOn == 0" style="color:gray">gps_off</i>
                <i class="material-icons-outlined md-16" v-if="trackingOn == 1" style="color:crimson">gps_not_fixed</i>
                <i class="material-icons-outlined md-16" v-if="trackingOn == 2" style="color:royalblue">gps_fixed</i>
            </button>
        </div>
        <div class="btn-group dropleft float-right d-none">
            <button id="btn-search" class="btn-right" type="button" data-toggle="dropdown" data-target="drop-search">
                <i class="material-icons-outlined">search</i>
            </button>
            <ul id="drop-search" class="dropdown-menu">
                <li>
                    <input type="search" />
                </li>
            </ul>
        </div>
        <span id="compass" @click="mapView.setRotation(0)" :style="{transform:`rotate(${rotation}rad)`}">
            <svg>
                <circle cx="32" cy="32" fill="rgba(0,0,0,0.1)" r="28" stroke="#fff" stroke-width="1px"></circle>
                <text fill="#fff" x="28" y="16" font-size="12">N</text>
            </svg>
            <i class="material-icons-outlined md-18">navigation</i>
        </span>
        <img id="geolocation_marker" :class="trackingOn == 1 ? 'lost' : trackingOn == 0 ? 'off' : 'ok'" src="https://openlayers.org/en/latest/examples/data/geolocation_marker.png" />
        <span :class="trackingOn && (speed > 1/36) ? 'on' : 'off'" id="speedometer">
            {{(speed * 3.6).toFixed(speed * 3.6 >= 10 ? 0 : 1)}}<br /><sup>km/h</sup>
        </span>
        <slot name="custom-controls"></slot>
        <menu ref="featuresMenu" :class="{ active: selFeatures.length }">
            <ul v-if="selFeatures.length">
                <li v-for="(feature, iFeature) in getSelFeatures()" :key="`feature-menu-${feature.Id}`">
                    <slot :slot-key="`clicked-features-slot-${feature.Id}`" :index="iFeature" :item="feature">
                        <img class="default" :src="feature.img" />
                        <span class="default">
                            <strong :style="{color: feature.color}">{{feature.title}}</strong><br />
                            <small><b>{{feature.caption}}</b></small>
                        </span>
                    </slot>
                    <slot name="featureActions" :slot-key="`clicked-features-actions-slot-${feature.Id}`" :index="iFeature" :item="feature">
                        <i @click="showInfo(feature)" class="material-icons md-18">info</i>
                        <i v-if="trackingOn" class="material-icons md-18" style="color:gray">gps_off</i>
                        <i v-else @click="flyToMapPoint(feature.geo)" class="material-icons md-18">gps_fixed</i>
                        <i @click="navigateTo(feature)" class="material-icons md-18">directions</i>
                    </slot>
                </li>
            </ul>
        </menu>
    </div>
</template>

<script>
    import Icon from '../icons/Icon.vue';
    import 'ol/ol.css';
    import { Feature, Geolocation, Map, View } from 'ol';
    import * as olExtent from 'ol/extent';
    import * as olInteraction from 'ol/interaction';
    import * as olControl from 'ol/control';
    import { LineString, Point } from 'ol/geom';
    import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
    import { fromLonLat, toLonLat } from 'ol/proj';
    import { Cluster, Vector as VectorSource, XYZ } from 'ol/source';
    import { Circle as CircleStyle, Fill, Icon as olIcon, Stroke, Style, Text } from 'ol/style';
    import DataProvider from '../../assets/DataProvider';
    import { mod } from '../../assets/Math';
    import { apply as mbApply, getLayer as mbGetLayer } from 'ol-mapbox-style';

    export default {
        name: 'OpenLayersMap',
        components: {
            Icon
        },
        props: ['clusters', 'controls', 'interactions', 'sort', 'start', 'labeler'],
        data() {
            return {
                map: null,
                mapView: null,
                streetSources: [
                    'https://api.maptiler.com/maps/streets/style.json?key=lFFvMe5AeuC9ONwTA9KH',
                    'https://api.maptiler.com/maps/7079891b-3b29-4c17-986e-e729731bd4e7/style.json?key=lFFvMe5AeuC9ONwTA9KH',
                ],
                styleSrc: 'https://api.maptiler.com/maps/6b2c954e-c487-4b02-9735-7ee5b63e5932/style.json?key=lFFvMe5AeuC9ONwTA9KH',
                satelliteOn: false,
                layerSourceIdx: 0,
                tileLayer: null,
                vectorLayer: null,
                mapLayers: [],
                geoLocation: null,
                geoTracking: new LineString([], 'XYZM'),
                deltaMean: 500,
                previousM: 0,
                rotation: 0,
                speed: 0,
                trackingOn: 0,
                trackingTimer: null,
                features: [],
                featureIds: [],
                selFeatures: [],
                perspectiveOn: false,
                nightModeOn: 0,
                selectedHotelId: null,
            };
        },
        watch: {
            clusters() {
                this.updateLayers();
            },
            controls() {
                this.updateControls();
            },
            interactions() {
                this.updateInteractions();
            }
        },
        methods: {
            updateBase() {
                var $this = this;
                mbGetLayer($this.map, 'satellite').setVisible($this.satelliteOn);
                $this.$nextTick(() => {
                    [...document.querySelectorAll('.ol-layer'), { style: '' }].shift().style.backgroundColor = $this.nightModeOn ? 'rgba(0,0,0,.75)' : 'transparent';
                });
            },
            toggleSubmenu() {
                var btn = event.currentTarget;

                if (btn.getAttribute('aria-expanded') == 'true') {
                    btn.nextElementSibling.classList.remove('on');
                    btn.nextElementSibling.classList.add('off');
                    btn.setAttribute('aria-expanded', 'false')
                }
                else {
                    btn.nextElementSibling.classList.remove('off');
                    btn.nextElementSibling.classList.add('on');
                    btn.setAttribute('aria-expanded', 'true')
                }
            },
            updateControls() {
                var $this = this;

                if (!$this.map)
                    return;

                while ($this.map.getControls().getLength())
                    $this.map.getControls().pop()

                let controls = olControl.defaults($this.controls);
                controls.forEach(c => $this.map.addControl(c));

                $this.controls && $this.controls.fullScreen && $this.map.addControl(new olControl.FullScreen());
                $this.controls && $this.controls.zoomSlider && $this.map.addControl(new olControl.ZoomSlider());
                $this.controls && $this.controls.zoomToExtent && $this.map.addControl(new olControl.ZoomToExtent());
            },
            updateInteractions() {
                var $this = this;

                if (!$this.map)
                    return;

                while ($this.map.getInteractions().getLength())
                    $this.map.getInteractions().pop();

                let interactions = olInteraction.defaults($this.interactions);
                interactions.forEach(i => $this.map.addInteraction(i));
            },
            updateLayers() {
                var $this = this;

                $this.featureIds = [];
                for (let src of $this.clusters) {
                    var styleCache = {};
                    var styles = {
                        'route': new Style({
                            stroke: new Stroke({
                                width: 6, color: [237, 212, 0, 0.8]
                            })
                        }),
                        'icon': new Style({
                            image: new olIcon({
                                anchor: [0.5, 1],
                                size: [26, 40],
                                src: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgMjEuMDIyNzQxIDMyLjc0MjcyMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxkZWZzPgogIDxyYWRpYWxHcmFkaWVudCBpZD0iYSIgY3g9IjEyLjUiIGN5PSIyLjMzNTEiIHI9IjkuOTYyNSIgZ3JhZGllbnRUcmFuc2Zvcm09Im1hdHJpeCguOTkxNjggLjAwNDE2MyAtLjAwNDE5OCAuOTk5OTkgLjExMzg3IC0uMDUyMDEyKSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICA8c3RvcCBzdG9wLWNvbG9yPSIjZmZmIiBvZmZzZXQ9IjAiLz4KICAgPHN0b3Agc3RvcC1jb2xvcj0iIzAwNGRmZiIgb2Zmc2V0PSIxIi8+CiAgPC9yYWRpYWxHcmFkaWVudD4KIDwvZGVmcz4KIDxnIHRyYW5zZm9ybT0ibWF0cml4KC0uODQyOTEgLjAxMzMxNiAuMDEzMzE2IC44NDI5MSA3LjM4NzcgLTg1NC4zNSkiIG9wYWNpdHk9Ii42NSI+CiAgPHBhdGggZD0ibTEyLjUgMTAxMy45Yy02LjYxNjkgMC0xMS45OCA1LjM1NDctMTEuOTggMTEuOTYyIDAgNC43MzU3IDIuNzczMyA4LjgyMDYgNi43NzEgMTAuNzU5bDUuMjA4NSAxNC4yMzhjMS45MzY3LTQuNzI5IDMuNjg2OS05LjUyNDEgNS40MDM4LTE0LjMzNSAzLjg5MDgtMS45NzY0IDYuNTc1Ny02LjAwNDMgNi41NzU3LTEwLjY2MiAwLTYuNjA3NS01LjM2MjYtMTEuOTYyLTExLjk4LTExLjk2MnoiIGZpbGw9IiMxOTVmZmYiIHN0cm9rZT0iIzAwMCIvPgogIDxwYXRoIHRyYW5zZm9ybT0ibWF0cml4KDEuMDUyMyAuMzM0MzEgLS4zMzQzMSAxLjA1MjMgMy4xOTA3IDEwMDkuNikiIGQ9Im0yMS45NjQgMTEuNWMwIDUuMjI3LTQuMjM3MyA5LjQ2NDMtOS40NjQzIDkuNDY0M3MtOS40NjQzLTQuMjM3My05LjQ2NDMtOS40NjQzIDQuMjM3My05LjQ2NDMgOS40NjQzLTkuNDY0MyA5LjQ2NDMgNC4yMzczIDkuNDY0MyA5LjQ2NDN6IiBmaWxsPSJ1cmwoI2EpIi8+CiA8L2c+Cjwvc3ZnPgo="
                            })
                        }),
                        'geoMarker': new Style({
                            image: new CircleStyle({
                                radius: 8,
                                fill: new Fill({ color: src.style && src.style.bgColor || '#fff6' }),
                                stroke: new Stroke({
                                    color: src.style && src.style.color || 'royalblue', width: 1
                                })
                            })
                        })
                    };

                    if (src instanceof Cluster) {
                        var expLayer = new VectorLayer({
                            source: src,
                            zIndex: 2,
                            style: function (feature) {
                                var size = feature.get('features').length;
                                if (size == 1) {
                                    return $this.singleFeature(feature.get('features')[0].get('data'));
                                }
                                else {
                                    return $this.clusterFeature(src, size);
                                }
                            }
                        });

                        expLayer.setVisible(true);
                        $this.mapLayers.push({ i: src.icon || 'local_activity', l: expLayer, t: 'Layer ', v: false, r: src.Id });
                        $this.map.addLayer(expLayer);
                        const extent = expLayer.getSource().getExtent();
                        try {
                            $this.map.getView().fit(extent, $this.map.getSize());
                        }
                        catch { }

                        continue;
                    }

                    var rect = $this.mapView.calculateExtent($this.map.getSize());
                    var nw = toLonLat(olExtent.getTopLeft(rect));
                    var se = toLonLat(olExtent.getBottomRight(rect));
                    DataProvider.getChannelItems(
                        src.channel.Path,
                        0, 500, 'dte_init desc', `mapa;${nw[1]};s&query=mapa;${nw[0]};e&query=mapa;${se[1]};n&query=mapa;${se[0]};w&filterFields=mapa,*nome,*name,*titulo,imagem_pequena,images,imagem,NBC_Images.image1`, $this.$i18n.culture, 0).then(
                            data => {
                                var features = [];

                                for (var i = 0; i < data.length; ++i) {
                                    if ($this.featureIds.indexOf(data[i].Id) < 0) {
                                        var f = new Feature(new Point(fromLonLat([data[i].Parts.mapa.Longitude, data[i].Parts.mapa.Latitude])));

                                        data[i].title = data[i].Title;
                                        data[i].caption = src.title || src.channel.Name;
                                        data[i].geo = data[i].Parts.mapa;
                                        data[i].color = src.style && src.style.color || 'royalblue';
                                        for (var k in data[i].Parts) {
                                            if (data[i].Parts[k].Src) {
                                                data[i].img = data[i].Parts[k].Src;
                                                break;
                                            }
                                        }
                                        f.set('data', data[i], true);
                                        features.push(f);
                                        $this.featureIds.push(data[i].Id);
                                    }
                                }

                                var source = new VectorSource({ features });

                                var clusterSource = new Cluster({
                                    distance: 50,
                                    source: source
                                });



                                let oldLayer = $this.mapLayers.filter(oL => oL.r == src.channel.Id).pop();

                                if (oldLayer) {
                                    //debugger;
                                    oldLayer.l.setSource(clusterSource);
                                } else {
                                    var expLayer = new VectorLayer({
                                        source: clusterSource,
                                        zIndex: 2,
                                        style: function (feature) {
                                            var size = feature.get('features').length;
                                            if (size == 1)
                                                return styles.geoMarker;

                                            var style = styleCache[size];
                                            if (!style) {
                                                style = new Style({
                                                    image: new CircleStyle({
                                                        radius: 12,
                                                        fill: new Fill({ color: src.style && src.style.bgColor || 'white' }),
                                                        stroke: new Stroke({
                                                            color: src.style && src.style.color || 'royalblue', width: 1
                                                        })
                                                    }),
                                                    text: new Text({
                                                        text: size.toString(),
                                                        font: '10px bold MuseoModerno, sans-serif',
                                                        fill: new Fill({
                                                            color: src.style && src.style.color || 'royalblue'
                                                        })
                                                    })
                                                });
                                                styleCache[size] = style;
                                            }
                                            return style;
                                        }
                                    });


                                    expLayer.setVisible(false);
                                    $this.mapLayers.push({ i: src.icon || 'local_activity', l: expLayer, t: src.channel.Name, v: false, r: src.channel.Id });
                                    $this.map.addLayer(expLayer);
                                }
                            });
                }
            },
            toggleLayer(i) {
                if (i < this.mapLayers.length) {
                    this.mapLayers[i].v = !this.mapLayers[i].v;
                    this.mapLayers[i].l.setVisible(this.mapLayers[i].v);
                }
            },
            getSelFeatures() {
                var ff = [];

                for (let feature of this.selFeatures) {
                    var feats = feature.get('features');
                    if (feats)
                        ff = [...ff, ...feats.map(f => f.get('data'))];
                }

                if (this.sort)
                    return this.sort(ff);

                return ff;
            },
            toggleTracking() {
                var $this = this;

                $this.trackingOn = $this.trackingOn ? 0 : 1;
                if ($this.trackingOn) {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(p => {
                            $this.flyTo(fromLonLat([
                                p.coords.longitude,
                                p.coords.latitude
                            ]), () => {
                                $this.trackingOn = 2;
                                $this.$nextTick(() => {
                                    $this.geoLocation.setTracking(true); // Start position tracking
                                    $this.tileLayer.on('postrender', $this.updateView);
                                    $this.map.render();
                                });
                            });
                        });
                    }
                    else {
                        $this.trackingOn = 0;
                        $this.geoLocation.setTracking(false); // Stop position tracking
                        alert("Geo Location not supported by system");
                    }
                }
                else {
                    $this.geoLocation.setTracking(false); // Stop position tracking
                }
            },
            flyTo(location, done) {
                var $this = this;
                var duration = 4000;
                var zoom = $this.mapView.getZoom();
                var parts = 2;
                var called = false;

                function callback(complete) {
                    --parts;
                    if (called) {
                        return;
                    }
                    if (parts === 0 || !complete) {
                        called = true;
                        done(complete);
                    }
                }

                $this.mapView.animate({
                    center: location,
                    duration: duration
                }, callback);
                $this.mapView.animate({
                    zoom: zoom * 2 / 3,
                    duration: duration / 2
                }, {
                    zoom: 17,
                    duration: duration / 2
                }, callback);
            },
            flyToMapPoint(point) {
                this.flyTo(fromLonLat([
                    point.Longitude,
                    point.Latitude
                ]), function () { });
            },
            setSelectedHotelId(hotelId) {
                this.selectedHotelId = hotelId;
                //this.map.getLayers().item(0).getSource().dispatchEvent('change');
            },
            updateView() {
                var $this = this;

                if ($this.trackingOn) {
                    // use sampling period to get a smooth transition
                    let m = Date.now() - $this.deltaMean * 1.5;
                    m = Math.max(m, $this.previousM);
                    $this.previousM = m;
                    // interpolate position along positions LineString
                    const c = $this.geoTracking.getCoordinateAtM(m, true);
                    if (c) {
                        const size = $this.map.getSize();
                        const height = size[1];
                        const resolution = $this.mapView.getResolution();
                        if ($this.speed >= 1) {
                            $this.rotation = -c[2];
                        }

                        let center = [
                            c[0] - Math.sin($this.rotation) * height * resolution * 1 / 4,
                            c[1] + Math.cos($this.rotation) * height * resolution * 1 / 4
                        ];

                        $this.mapView.setCenter(center);
                        $this.mapView.setRotation($this.rotation);
                        $this.map.render();
                    }
                }
            },
            addPosition(position, heading, m, speed) {
                var $this = this;

                if ($this.trackingTimer)
                    clearTimeout($this.trackingTimer);

                $this.trackingOn = 2;
                $this.trackingTimer = setTimeout(() => {
                    if ($this.trackingOn == 2)
                        $this.trackingOn = 1;
                }, 10000);

                const x = position[0];
                const y = position[1];
                const fCoords = $this.geoTracking.getCoordinates();
                const previous = fCoords[fCoords.length - 1];
                const prevHeading = previous && previous[2];
                if (prevHeading) {
                    let headingDiff = heading - mod(prevHeading);
                    // force the rotation change to be less than 180�
                    if (Math.abs(headingDiff) > Math.PI) {
                        const sign = (headingDiff >= 0) ? 1 : -1;
                        headingDiff = -sign * (2 * Math.PI - Math.abs(headingDiff));
                    }
                    heading = prevHeading + headingDiff;
                }
                $this.geoTracking.appendCoordinate([x, y, heading, m]);
                // only keep the 20 last coordinates
                $this.geoTracking.setCoordinates($this.geoTracking.getCoordinates().slice(-20));

                $this.speed = speed;
                if (speed >= 1 / 36) {
                    document.getElementById('geolocation_marker').src = 'https://openlayers.org/en/latest/examples/data/geolocation_marker_heading.png';
                }
                else {
                    document.getElementById('geolocation_marker').src = 'https://openlayers.org/en/latest/examples/data/geolocation_marker.png';
                }
            },
            singleFeature(data) {
                var color = getComputedStyle(document.documentElement).getPropertyValue('--primary') || 'royalblue';
                if (this.selectedHotelId && data.Id == this.selectedHotelId)
                    color = 'red';

                var svg = `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -256 1792 1792" version="1.1" width="40" height="40"><g transform="matrix(1,0,0,-1,364.47458,1270.2373)" id="g3027"><path d="m 768,896 q 0,106 -75,181 -75,75 -181,75 -106,0 -181,-75 -75,-75 -75,-181 0,-106 75,-181 75,-75 181,-75 106,0 181,75 75,75 75,181 z m 256,0 q 0,-109 -33,-179 L 627,-57 q -16,-33 -47.5,-52 -31.5,-19 -67.5,-19 -36,0 -67.5,19 Q 413,-90 398,-57 L 33,717 Q 0,787 0,896 q 0,212 150,362 150,150 362,150 212,0 362,-150 150,-150 150,-362 z" id="path3029" style="fill:${color}" /></g></svg>`;
                return new Style({
                    image: new olIcon({
                        opacity: 1,
                        src: 'data:image/svg+xml;base64,' + btoa(svg),
                        scale: 1.2
                    }),
                    text: this.labeler && new Text({
                        text: this.labeler(data),
                        font: '12px bold Lato, sans-serif',
                        fill: new Fill({
                            color: getComputedStyle(document.documentElement).getPropertyValue('--primary')
                        }),
                        backgroundFill: new Fill({
                            color: '#ffffffdd'
                        }),
                        textAlign: 'left',
                        padding: [3, 0, 0, 3],
                        offsetY: 16,
                        offsetX: 0
                    })
                });
            },
            clusterFeature(src, size) {
                return new Style({
                    image: new CircleStyle({
                        radius: 12,
                        fill: new Fill({ color: src.style && src.style.bgColor || 'white' }),
                        stroke: new Stroke({
                            color: src.style && src.style.color || getComputedStyle(document.documentElement).getPropertyValue('--primary'), width: 5
                        })
                    }),
                    text: new Text({
                        text: size.toString(),
                        font: '11px bold Lato, sans-serif',
                        fill: new Fill({
                            color: src.style && src.style.color || getComputedStyle(document.documentElement).getPropertyValue('--primary')
                        })
                    })
                });
            },
        },
        beforeMount() {
            var $this = this;

            $this.mapView = new View({
                constrainResolution: true,
                center: fromLonLat([($this.start && $this.start.longitude) || -8.0151267, ($this.start && $this.start.latitude) || 38.5916642]),
                zoom: ($this.start && $this.start.zoom) || 7.4
            });

            $this.mapView.on('change:rotation', () => {
                $this.rotation = $this.mapView.getRotation();
            });

            $this.geoLocation = new Geolocation({
                trackingOptions: {
                    maximumAge: 10000,
                    enableHighAccuracy: true,
                    timeout: 600000
                },
                projection: $this.mapView.getProjection()
            });

            $this.geoLocation.on('change', () => {
                const position = $this.geoLocation.getPosition();
                const heading = $this.geoLocation.getHeading() || 0;
                const speed = $this.geoLocation.getSpeed() || 0;
                const m = Date.now();
                $this.addPosition(position, heading, m, speed);
                const coords = $this.geoTracking.getCoordinates();
                const len = coords.length;

                if (len >= 2) {
                    $this.deltaMean = (coords[len - 1][3] - coords[0][3]) / (len - 1);
                }
            });

            $this.geoLocation.on('error', function () {
                alert('geolocation error');
            });

            var l;
            try {
                navigator.getWakeLock("screen").then((a) => {
                    l = a.createRequest()
                })
            }
            catch (a) { }

        },
        mounted() {
            var $this = this;

            $this.tileLayer = new TileLayer({
                source: new XYZ({
                    url: 'https://api.maptiler.com/tiles/satellite/{z}/{x}/{y}.jpg?key=lFFvMe5AeuC9ONwTA9KH'
                }),
                zIndex: 0
            });

            $this.vectorLayer = new VectorLayer({
                zIndex: 1
            });

            $this.$nextTick(() => {
                $this.map = new Map({
                    controls: [],
                    interactions: [],
                    target: $this.$el.firstElementChild,
                    view: $this.mapView
                });

                mbApply($this.map, $this.styleSrc);

                $this.map.on('click', (event) => {
                    let sTime = Date.now();

                    var clickFeatures = $this.map.getFeaturesAtPixel(event.pixel, {
                        hitTolerance: 0
                    });

                    //console.log(Date.now() - sTime);

                    if ($this.$refs.featuresMenu.classList.contains('active')) {
                        $this.$refs.featuresMenu.classList.remove('active');
                        setTimeout(() => {
                            $this.selFeatures = clickFeatures;
                            if (clickFeatures.length)
                                $this.$refs.featuresMenu.classList.add('active');
                        }, 600)
                    } else {
                        $this.selFeatures = clickFeatures;

                    }
                });

                $this.updateControls();
                $this.updateInteractions();
                $this.updateLayers();
            });
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@300;700&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Sharp|Material+Icons+Round|Material+Icons+Two+Tone');


    #map-container, #map {
        width: 100%;
        height: 100%;
        transition: all 1s ease-in;
        background-color: white;
        position: relative;
        top: 0;
        left: 0;
    }

        #map.perspective {
            width: 100%;
            transform: rotateX(50deg) scale(1.6);
        }

    .ol-attribution {
        position: absolute;
        bottom: 0;
        font-size: 0;
    }

    .btn-right {
        position: absolute;
        right: .5em;
    }

    .ol-control button {
        background-color: rgba(255,255,255,.5);
        text-align: center;
        border: 1px solid #ddd;
        outline: 0;
        border-radius: 2px;
        color: gray;
    }

        .ol-control button:focus, .ol-control button:hover {
            text-decoration: none;
            background-color: rgba(255,255,255,.7);
        }

    .dropdown-menu button {
        margin: 0px 2px;
        vertical-align: middle;
        border: 0;
        background: transparent;
        text-align: center;
        outline: 0;
        position: relative;
    }

        .btn-right i,
        .dropdown-menu button i {
            font-size: 16px;
            width: 16px;
            height: 16px;
            display: block;
            margin: 3px;
            color: gray;
        }

        .btn-right[aria-expanded=true] i,
        .dropdown-menu button[aria-expanded=true] i {
            color: royalblue;
        }

    .submenu > button i::after {
        content: '\25B2';
        position: absolute;
        top: -3px;
        right: 3px;
        font-size: 0.3em;
    }

    .submenu > span a {
        display: block;
        padding: 4px;
        white-space: nowrap;
        cursor: pointer;
        padding-right: 32px;
        position: relative;
    }

        .submenu > span a i {
            display: block;
            padding: 4px;
            position: absolute;
            right: 0;
            top: 5px;
        }

    #layers-menu {
        position: absolute;
        right: -4px;
        bottom: 26px;
        background: #fff;
        padding: 5px 15px 5px 5px;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: fit-content;
        transition: opacity .6s linear;
    }

        #layers-menu.off {
            opacity: 0;
            pointer-events: none;
        }

    #btn-search {
        bottom: 4.5em;
    }

    #btn-tracking {
        bottom: 2.5em;
    }

    #btn-options {
        bottom: .5em;
    }


    #geolocation_marker {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%,-50%);
        transition: all 1s ease-in-out;
        pointer-events: none;
    }



        #geolocation_marker.off {
            opacity: 0;
        }

        #geolocation_marker.lost {
            filter: hue-rotate(140deg);
        }

    #compass {
        position: absolute;
        top: 5px;
        z-index: 999;
        right: 5px;
        width: 64px;
        height: 64px;
    }

        #compass svg {
            width: 64px;
            height: 64px;
        }

        #compass i {
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
        }

    #speedometer {
        font-family: MuseoModerno,cursive;
        color: #fff;
        font-weight: 700;
        position: absolute;
        top: 5px;
        right: 70px;
        background: rgba(0,0,0,.3);
        font-size: 23px;
        width: 60px;
        height: 60px;
        padding: 5px;
        border-radius: 100%;
        text-align: center;
        border: 2px solid #fff;
        transition: all 1s ease-in-out;
        pointer-events: none;
    }

        #speedometer.off {
            opacity: 0;
        }

        #speedometer sup {
            font-weight: 300;
            font-size: 12px;
            position: absolute;
            top: 50px;
            right: 70px;
            width: 60px;
            display: block;
            text-align: center;
        }

    menu {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 98%;
        z-index: 1000;
        padding: 10px 0;
        margin: 0;
        display: block;
        max-height: 40vh;
        transform: translateX(100%);
        transition: all .5s linear;
        overflow: auto;
        -webkit-mask-image: linear-gradient(transparent 0, black 5%, black 95%, transparent 100%);
    }

        menu.active {
            transform: none;
        }

        menu ul {
            white-space: nowrap;
            list-style: none;
            margin: 0;
            padding: 0;
            background: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            box-shadow: 1px 1px 10px rgba(0,0,0,.3);
            display: flex;
            flex-direction: column;
        }

            menu ul li {
                padding: 10px;
                box-shadow: 1px 1px 1px #333;
                display: flex;
                flex-direction: row;
                min-width: 50vw;
            }

                menu ul li img.default {
                    width: 67px;
                    height: 50px;
                    margin: -9px 5px -9px -9px;
                }

        menu li span.default {
            display: block;
            width: calc(100% - 60px);
            font-size: 0.68em;
            overflow: hidden;
            white-space: pre-wrap;
            height: 3.3em;
        }

            menu li span.default strong {
                color: royalblue;
            }

            menu li span.default i {
                color: #7c7ccbba;
                float: right;
                margin: 4px;
            }
</style>