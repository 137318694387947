<template>
    <VuePhoneNumberInput v-model="valueModel" contenteditable="true" :no-validator-state="true" @update="updateValue"> </VuePhoneNumberInput>
</template>
<script>

    import Vue from 'vue'
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    Vue.component('vue-phone-number-input', VuePhoneNumberInput);

    export default {
        name: 'HtmlInput',
        components: {
            VuePhoneNumberInput
        },
        props: {
            value: { type: String, default: '' },
            placeholder: { type: String, default: '' }
        },
        data() {
            return {
                countryCode: '',///^\+([0-9])*/.exec(this.value)[1],
                valueModel: this.value.replace(/^\+[0-9]* */, '')
            }
        },
        methods: {
            updateValue: function (a) {
                //debugger

                this.valueModel = a.formatNational
                this.countryCode = a.countryCallingCode
                this.$emit('input', '+' + this.countryCode + ' ' + this.valueModel)
            }
        },
        mounted() {
        }
    }
</script>

<style>
    :root {
        --phone-number-valid-color: #ddd;
    }
</style>