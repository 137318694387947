<template>
    <i ref="icon" v-html="svg"></i>
</template>

<script>
    export default {
        name: 'icon',
        data() {
            return {
                svg: null
            };
        },
        props: ['src'],
        beforeMount() {
            fetch(this.src).then(r => r.text().then(t => this.svg = t));
        }
    }
</script>

<style scoped>
</style>