import DataProvider from './DataProvider'
//import Vue from 'vue'

export default {
    install: function (Vue, /*options*/) {
        Vue.prototype.$i18n = this;
        Vue.prototype.$trans = this._getTrans;
    },
    //ABREU_B2B
    //culture: localStorage.getItem('culture') || 'es-ES',
    //OTHER SITES
    culture: localStorage.getItem('culture') || 'pt-PT',
    setCulture(culture) {
        localStorage.setItem('culture', culture);
        document.cookie = 'language=' + culture;

        const url = document.location.href;
        const pattern = /.*-[0-9A-Fa-f]{8}$/;
        if (pattern.test(url)) {
            /*is a cotent page, redirect to home */
            document.location.href = document.location.href.replace(document.location.pathname, '');
        }
        else {
            document.location = document.location;
        }
        
        
    },
    //ABREU_B2B
    //_cache: JSON.parse(localStorage.getItem('transcache_' + (localStorage.getItem('culture') || 'es-ES')) || "{}"),  
    //OTHER SITES
    _cache: JSON.parse(localStorage.getItem('transcache_' + (localStorage.getItem('culture') || 'pt-PT')) || "{}"),  
    _keys: [],
    _toUpdate: [],
    _timer: null,
    _getTrans: function (k) {
        let $vm = this,
            $i18n = $vm.$i18n;


        var ret = k;

        if ($i18n._toUpdate.filter(vm => vm._uid == $vm._uid).length == 0)
            $i18n._toUpdate.push($vm)

        localStorage.setItem('transkeys', JSON.stringify($i18n._keys))

        if (k in $i18n._cache && $i18n._keys.indexOf(k) > -1) {
            ret = $i18n._cache[k] || k;
        }
        else {

            if ($i18n._keys.indexOf(k) < 0)
                $i18n._keys.push(k);

            if ($i18n._timer)
                clearTimeout($i18n._timer);

            $i18n._timer = setInterval(() => {

                var missingKeys = $i18n._keys.filter(kf => !(kf in $i18n._cache && $i18n._cache[kf]));

                if (missingKeys.length)
                    DataProvider.getTranslations(missingKeys, $i18n.culture, $vm.$store).then(function (res) {

                        for (var kr in res)
                            $i18n._cache[kr] = res[kr];

                        localStorage.setItem('transcache_' + $i18n.culture, JSON.stringify($i18n._cache))

                        $i18n._toUpdate.forEach(vm => vm.$forceUpdate());
                        $i18n._toUpdate = []
                        //k in $i18n._cache &&
                        //    $i18n._cache[k] &&
                        //    $vm &&
                        //    $vm.$forceUpdate &&
                        //    $vm.$forceUpdate();
                    });
            }, 500);
        }

        for (var i = 0; i < arguments.length - 1; i++) {
            ret = ret.replace('{' + i + '}', arguments[i + 1]);
        }
        return ret;
    }
};