import AuthProvider from './AuthProvider'

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default {
    name: 'DataProvider',
    baseUrl: document.location.protocol + '//' + (document.location.hostname == "localhost" ? document.location.hostname + ":1002" : document.location.hostname) + '/rest',
    _pendingTemplates: [],
    authToken: document.location.search && /authtoken=([^&]*)/i.test(document.location.search) && /authtoken=([^&]*)/i.exec(document.location.search).pop() || '&authentication=anonymous',
    generateToken() {
        //let DataProvider = this;

        //fetch(DataProvider.baseUrl + '/generateToken', {
        //    method: 'GET'
        //}).then(r => {
        //    r.json().then(j => {
        //        if (j.Result == "OK")
        //            DataProvider.authToken = j.token;
        //    })
        //})
    },
    getConfig(culture, $store, $config) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {
            if ($store && $store.state && $store.state.appData && $store.state.appData[culture]) {
                resolve($store.state.appData[culture])
            }
            else {
                $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/getChannelProps?authtoken=' + DataProvider.authToken +
                    '&channelPath=' + $config.channels.website + '&lang=' + encodeURIComponent(culture.split('-')[0]) +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''), {
                    method: 'GET'
                })
                    .then(response => {
                        //debugger;
                        if (response.status != 200) {
                            reject(response.statusText);
                            return;
                        }
                        response.json().then((response) => {

                            /* Checking if error object was returned from the server */
                            if (response.Error) {
                                $store.state.serverPending--;
                                $store.state.serverFailed++;
                                reject(response.Message);
                            }
                            else {
                                $store.state.serverPending--;
                                $store.state.serverSuccess++;
                                $store && $store.commit('SET_APPDATA', { culture, data: response });
                                resolve(response);
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },

    getPropsData(path, culture, $store, forceOwn) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {

            if ($store && $store.state && !$store.state.adminMode && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][path]) {
                if (forceOwn && $store.state.dataCache[culture][path].Child)
                    resolve($store.state.dataCache[culture][path].Child)
                else
                    resolve($store.state.dataCache[culture][path])
            }
            else {
                $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/data/channelproperties?path=' + encodeURIComponent(path) + '&culture=' + encodeURIComponent(culture) +
                    (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '') +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : '') +
                    ($store && $store.state.adminMode ? '&admin=1' : ''), {
                    method: 'GET'
                })
                    .then(response => {
                        response.json().then((response) => {
                            /* Checking if error object was returned from the server */
                            if (response.Error) {
                                $store.state.serverPending--;
                                $store.state.serverFailed++;
                                reject(response.Message);
                            }
                            else if (response.Data && response.Data.length) {
                                $store.state.serverPending--;
                                $store.state.serverSuccess++;
                                var own = response.Data.shift();
                                var parent = response.Data.pop();
                                if (parent)
                                    parent.Child = own
                                $store && !$store.state.adminMode && $store.commit('SET_DATACACHE', { culture, path, data: parent || own })
                                resolve(forceOwn ? own : (parent || own));
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },

    getUserInfo() {


        return new Promise((resolve, reject) => {

            fetch(this.baseUrl + '/getuserinfo?authToken=' + this.authToken,
                { method: 'GET' }
            ).then(response => {
                response.json().then((response) => {
                    resolve(response);
                });

            }).catch(error => {
                reject(error);
            });


            //fetch(this.baseUrl + '/eval?authToken=' + this.authToken +
            //    '&code=%6f%62%6a%53%63%72%69%70%74%6f%72%2e%67%65%74%55%73%65%72%49%6e%66%6f%28%6f%62%6a%53%63%72%69%70%74%6f%72%2e%67%65%74%55%73%65%72%49%44%28%29%29%2e%4f%75%74%65%72%58%6d%6c',
            //    { method: 'GET' }
            //).then(response => {
            //    response.text().then((response) => {

            //        var xml2json = xml => {
            //            var el = xml.nodeType === 9 ? xml.documentElement : xml
            //            var h = {}
            //            var t = Array.from(el.childNodes || []).filter(e => e.nodeType === 3 || e.nodeType === 4).map(e => e.textContent).join('').trim()
            //            h[el.nodeName] = t.length ? t : {};
            //            Array.from(el.attributes || []).filter(a => a).map((a) => { try { h[el.nodeName]['@' + a.name] = a.value } catch { } }, {})
            //            Array.from(el.childNodes || []).filter(e => e.nodeType === 1).map(c => h[el.nodeName][c.nodeName] = xml2json(c)[c.nodeName])
            //            return h
            //        };

            //        resolve(xml2json((new window.DOMParser()).parseFromString(response, "text/xml")));
            //    });

            //}).catch(error => {
            //    reject(error);
            //});
        });
    },

    was_getPropsData(path, culture, $store, forceOwn) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {

            if ($store && $store.state && !$store.state.adminMode && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][path]) {
                if (forceOwn && $store.state.dataCache[culture][path].Child)
                    resolve($store.state.dataCache[culture][path].Child)
                else
                    resolve($store.state.dataCache[culture][path])
            }
            else {
                $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/data/channelproperties?path=' + encodeURIComponent(path) + '&culture=' + encodeURIComponent(culture) +
                    (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '') +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : '') +
                    ($store && $store.state.adminMode ? '&admin=1' : ''), {
                    method: 'GET'
                })
                    .then(response => {
                        response.json().then((response) => {
                            /* Checking if error object was returned from the server */
                            if (response.Error) {
                                $store.state.serverPending--;
                                $store.state.serverFailed++;
                                reject(response.Message);
                            }
                            else if (response.Data && response.Data.length) {
                                $store.state.serverPending--;
                                $store.state.serverSuccess++;
                                var own = response.Data.shift();
                                var parent = response.Data.pop();
                                if (parent)
                                    parent.Child = own
                                $store && !$store.state.adminMode && $store.commit('SET_DATACACHE', { culture, path, data: parent || own })
                                resolve(forceOwn ? own : (parent || own));
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getChannelContentData(channelId, contentId, culture, $store, refreshcache) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {
            if (!refreshcache && $store && !$store.state.adminMode && $store.state && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][channelId + contentId]) {
                resolve($store.state.dataCache[culture][channelId + contentId])
            }
            else {
                $store && $store.state.serverPending++;
                fetch(DataProvider.baseUrl + '/getContent?authToken=' + DataProvider.authToken +
                    '&contentID=' + contentId +
                    '&channelID=' + channelId, {
                    method: 'GET'
                })
                    .then(response => {
                        response.json().then((response) => {
                            //debugger;
                            /* Checking if error object was returned from the server */
                            if (response.Result != "OK") {
                                $store && $store.state.serverPending--;
                                $store && $store.state.serverFailed++;
                                reject(response);
                            }
                            else {
                                $store && $store.state.serverPending--;
                                $store && $store.state.serverSuccess++;
                                $store && !$store.state.adminMode && $store.commit('SET_DATACACHE', { culture, path: channelId + contentId, data: response.content })
                                resolve(response.content);
                            }
                        });
                    })
                    .catch(error => {
                        $store.state.serverPending--;
                        $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getChannelItems(channel, start, max, sort, search, culture, $store, abort, anonymous) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {

            let cacheKey = channel + '_' + start + '_' + max + '_' + sort + '_' + search

            if ($store && $store.state && $store.state.dataCache && $store.state.dataCache[culture] && $store.state.dataCache[culture][cacheKey]) {
                resolve($store.state.dataCache[culture][cacheKey])
            }
            else {
                $store && $store.state && $store.state.serverPending++;
                var idOrPath = '&channelPath=' + encodeURIComponent(channel.replace(/\/props_[^/]*$/, ''));

                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(channel)) {
                    idOrPath = `&channelID=${channel}`;
                }

                fetch(DataProvider.baseUrl + '/contentListConditional?authToken=' + DataProvider.authToken +
                    '&fieldList=*' +
                    '&first=' + start +
                    '&max=' + max +
                    '&sort=' + (sort || '') +
                    (search ? '&search=' + search + '' : ('&condition=dd.id,notEquals,' + uuidv4())) +
                    idOrPath +
                    '&culture=' + encodeURIComponent(culture) +
                    (!anonymous && AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '') +
                    ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''),
                    {
                        method: 'GET',
                        signal: abort && abort.signal
                    })
                    .then(response => {
                        response.json().then((response) => {
                            //debugger;
                            /* Checking if error object was returned from the server */
                            if (response.Result != 'OK') {
                                $store && $store.state && $store.state.serverPending--;
                                $store && $store.state && $store.state.serverFailed++;
                                reject(response.Result);
                            }
                            else {
                                $store && $store.state && $store.state.serverPending--;
                                $store && $store.state && $store.state.serverSuccess++;
                                //$store && $store.commit('SET_DATACACHE', { culture, path: cacheKey, data: response.Data.Contents })
                                resolve(response.content);
                            }
                        });
                    })
                    .catch(error => {
                        $store && $store.state && $store.state.serverPending--;
                        $store && $store.state && $store.state.serverFailed++;
                        reject(error)
                    })
            }
        });
    },
    getTemplate(template, $store, full) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {
            if (template == '00000000-0000-0000-0000-000000000000')
                resolve('<template><pre>Undefined Template</pre></template><script>export default {}</script><style></style>');
            else {
                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(template)) {
                    if ($store && $store.state && $store.state.templateCache && $store.state.templateCache[template.toLowerCase()]) {
                        resolve(full ? $store.state.templateCache[template.toLowerCase()] : $store.state.templateCache[template.toLowerCase()].xsl_str.replace(/\r?\n/g, ''))
                    }
                    else if (DataProvider._pendingTemplates.indexOf(template.toLowerCase()) > -1) {
                        setTimeout(() => {
                            DataProvider.getTemplate(template, $store, full).then(t => resolve(t));
                        }, 50)
                    }
                    else {
                        DataProvider._pendingTemplates = DataProvider._pendingTemplates || [];
                        DataProvider._pendingTemplates.push(template.toLowerCase())
                        $store.state.serverPending++;
                        fetch(DataProvider.baseUrl + '/getTemplate?authToken=' + DataProvider.authToken +
                            '&templateId=' + template, {
                            method: 'GET'
                        })
                            .then(response => {
                                response.json().then((response) => {
                                    /* Checking if error object was returned from the server */
                                    if (response.Result != "OK") {
                                        $store.state.serverPending--;
                                        $store.state.serverFailed++;
                                        reject(response.Message);
                                    }
                                    else if (response) {
                                        //debugger
                                        $store.state.serverPending--;
                                        $store.state.serverSuccess++;
                                        DataProvider._pendingTemplates = DataProvider._pendingTemplates.filter((t) => t != template.toLowerCase());
                                        $store && $store.commit('SET_TEMPLATECACHE', { id: template.toLowerCase(), data: response.Template })
                                        resolve(full ? response.Template : response.Template.xsl_str.replace(/\r?\n/g, ''));
                                    }
                                    else if (response.Templa && response.Data.Template) {
                                        //debugger
                                        $store.state.serverPending--;
                                        $store.state.serverSuccess++;

                                    }
                                });
                            })
                            .catch(error => {
                                $store.state.serverPending--;
                                $store.state.serverFailed++;
                                reject(error)
                            });
                    }
                }
                else {
                    resolve('<template><span class="danger">Invalid template ID</span></template><script>export default {}</script><style></style>');
                }
            }
        });
    },
    getSchema(id, $store) {
        DataProvider.generateToken();
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            if (id == '00000000-0000-0000-0000-000000000000')
                resolve({});
            else {
                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(id)) {
                    $store.state.serverPending++;
                    fetch(DataProvider.baseUrl + '/data/schema?schemaID=' + id +
                        ($store && $store.state && $store.state.refreshServerCache ? '&refreshcache=1' : ''), {
                        method: 'GET'
                    })
                        .then(response => {
                            response.json().then((response) => {
                                /* Checking if error object was returned from the server */
                                if (response.Error) {
                                    $store.state.serverPending--;
                                    $store.state.serverFailed++;
                                    reject(response.Message);
                                }
                                else if (response.Data) {
                                    //debugger
                                    $store.state.serverPending--;
                                    $store.state.serverSuccess++;
                                    resolve(response.Data);
                                }
                            });
                        })
                        .catch(error => {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(error)
                        });
                }
                else {
                    resolve({ error: 'Invalid schema ID' });
                }
            }
        });
    },
    getChannelSchema(id, $store) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {
            if (id == '00000000-0000-0000-0000-000000000000')
                resolve({});
            else {
                if (/^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(id)) {
                    $store.state.serverPending++;
                    fetch(DataProvider.baseUrl + '/fieldList?authToken=' + DataProvider.authToken +
                        '&channelID=' + id, {
                        method: 'GET'
                    })
                        .then(response => {
                            response.json().then((response) => {
                                /* Checking if error object was returned from the server */
                                if (response.Result != "OK") {
                                    $store.state.serverPending--;
                                    $store.state.serverFailed++;
                                    reject(response.Message);
                                }
                                else if (response) {
                                    //debugger
                                    $store.state.serverPending--;
                                    $store.state.serverSuccess++;
                                    resolve(response.fieldList);
                                }
                            });
                        })
                        .catch(error => {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(error)
                        });
                }
                else {
                    resolve({ error: 'Invalid channel ID' });
                }
            }
        });
    },
    getContentTemplate(content, $store) {
        return this.getTemplate(content.Template.Id, $store)
    },
    getTranslations(keys, culture, $store) {
        let DataProvider = this;
        return new Promise((resolve, reject) => {
            $store.state.serverPending++;

            fetch(DataProvider.baseUrl + '/data/Translate?culture=' + culture, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: "POST",
                body: keys.map(k => 'keys=' + encodeURIComponent(k)).join('&')
            })
                .then(response => {
                    response.json().then((response) => {
                        //debugger;
                        /* Checking if error object was returned from the server */
                        if (!response) {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(null);
                        }
                        else {
                            $store.state.serverPending--;
                            $store.state.serverSuccess++;
                            resolve(response);
                        }
                    });
                })
                .catch(error => {
                    $store.state.serverPending--;
                    $store.state.serverFailed++;
                    reject(error)
                })
        });
    },
    getChannelTree(channel, $store) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {
            $store.state.serverPending++;
            fetch(DataProvider.baseUrl + '/channelList?authToken=' + DataProvider.authToken +
                '&channelID=' + (channel || '7CF6D26B-C2EB-4B03-A63F-32E2FFA97149')
                /*+ '&culture=' + culture*/, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    method: "GET"
                })
                .then(response => {
                    response.json().then((response) => {
                        //debugger;
                        /* Checking if error object was returned from the server */
                        if (!response) {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(null);
                        }
                        else {
                            $store.state.serverPending--;
                            $store.state.serverSuccess++;
                            resolve(response);
                        }
                    });
                })
                .catch(error => {
                    $store.state.serverPending--;
                    $store.state.serverFailed++;
                    reject(error)
                })
        });
    },
    setContentData(channel, content, wfAction, $store) {
        let DataProvider = this;
        DataProvider.generateToken();
        return new Promise((resolve, reject) => {
            $store.state.serverPending++;
            var contentId = content.scrId || content.Id || content.id || content.ID;
            var fields = content.Parts || content;
            fetch(DataProvider.baseUrl + '/saveContent?authtoken=' + DataProvider.authToken
                + '&channelID=' + channel
                + (AuthProvider.token ? '&token=' + encodeURIComponent(AuthProvider.token) : '')
                + (contentId ? '&contentID=' + encodeURIComponent(contentId) : '')
                + (wfAction ? '&wfAction=' + encodeURIComponent(wfAction) : '')
                /*+ '&culture=' + culture*/, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    method: "POST",
                    body: Object.getOwnPropertyNames(fields).filter(k => fields[k]).map(k => k.toLowerCase() + '=' + encodeURIComponent(fields[k].Value !== undefined ? fields[k].Value : fields[k].Src !== undefined ? fields[k].Src : fields[k])).join('&')
                })
                .then(response => {
                    response.json().then((response) => {
                        //debugger;
                        /* Checking if error object was returned from the server */
                        if (response.Result != "OK") {
                            $store.state.serverPending--;
                            $store.state.serverFailed++;
                            reject(null);
                        }
                        else {
                            $store.state.serverPending--;
                            $store.state.serverSuccess++;
                            resolve(response);
                        }
                    });
                })
                .catch(error => {
                    $store.state.serverPending--;
                    $store.state.serverFailed++;
                    reject(error)
                })
        });
    },
};
