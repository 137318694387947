<template>
    <select class="channel-items-select" v-model="valueModel" @change="onChange">
        <option v-if="defaultOption" :value="defaultOption.value||''" disabled>{{defaultOption.text}}</option>
        <template v-for="(item, index) in currentPage">
            <slot :slot-key="'channel-items-slot-' + item.Id" :index="index + start" :item="item"></slot>
        </template>
    </select>
</template>

<script>
    import DataProvider from '../../assets/DataProvider'
    export default {
        name: 'channel-items-select',
        props: ['channel', 'start', 'max', 'sort', 'search', 'defaultOption', 'value' ],
        data() {
            //debugger;
            return {
                valueModel: this.value,
                currentPage: []
            };
        },
        methods: {
            onChange(event) {
                this.$emit('input', this.valueModel);
                this.$emit('change', event);
            }
        },
        beforeMount() {
            DataProvider.getChannelItems(this.channel, this.start, this.max, this.sort, this.search, this.$i18n.culture, this.$store)
                .then(r => {
                    this.currentPage = r;
                });
        }
    }
</script>

<style scoped>
</style>