<template>
    <div><!-- Dummy div to be replaced --></div>
</template>

<script>
    import DataProvider from '../../assets/DataProvider';
    import ComponentBuilder from '../../assets/ComponentBuilder';
    //import ComponentProvider from '../../assets/ComponentProvider';

    import ArrayInput from '../forms/ArrayInput.vue';
    import ChannelItemsSelect from '../forms/ChannelItemsSelect.vue';
    import DateInputGroup from '../forms/DateInputGroup.vue';
    import HtmlInput from '../forms/HtmlInput.vue';
    import ImageDropTarget from '../forms/ImageDropTarget.vue';
    import PhoneNumberInput from '../forms/PhoneNumberInput.vue';

    import ChannelLink from '../helpers/ChannelLink.vue';
    import ContentLink from '../helpers/ContentLink.vue';
    import HeadSection from '../helpers/HeadSection.vue';

    import Block from '../views/Block.vue';
    import ChannelItems from '../views/ChannelItems.vue';
    import Page from '../views/Page.vue';
    import RoutedBlock from '../views/RoutedBlock.vue';
    

    import * as VuetifyComponents from 'vuetify/lib'

    export default {
        name: 'Layout',
        components: {
            ArrayInput,
            ChannelItemsSelect,
            DateInputGroup,
            HtmlInput,
            ImageDropTarget,
            PhoneNumberInput,

            ChannelLink,
            ContentLink,
HeadSection,
            HeadSection,

            Block,
            ChannelItems,
            Page,
            RoutedBlock,
            ...VuetifyComponents
        },
        props: {
            model: { required: true }
        },
        methods: {
        },
        data() {
            return {
            };
        },
        mounted() {
            var $layout = this;
            DataProvider.getContentTemplate($layout.model, $layout.$store).then(t => {
                ComponentBuilder.buildPageComponent($layout.model, t, $layout, {
                    ArrayInput,
                    ChannelItemsSelect,
                    DateInputGroup,
                    HtmlInput,
                    ImageDropTarget,
                    PhoneNumberInput,

                    ChannelLink,
                    ContentLink,
HeadSection,

                    Block,
                    ChannelItems,
                    Page,
                    RoutedBlock,
                    ...VuetifyComponents
                });
            })
                .catch(e => {
                    $layout.$el.innerText = e.toString();
                });

        }
    }
</script>
<style>
</style>