<template>
    <div>
        <block :template="appData[$i18n.culture].Layout.Parts.logintemplate.Id" :block="appData[$i18n.culture].Layout"></block>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    import Block from './Block.vue';
    export default {
        name: 'Login',
        components: {
            Block
        },
        computed: {
            ...mapState(['appData'])
        },
        mounted() {
            clearInterval(window.paceinterval);
            document.body.classList.remove('pace-running');
            document.body.classList.add('pace-done');
            document.querySelector('.pace').classList.add('pace-inactive');
        }
    }
</script>

<style scoped>
</style>