const env = process.env.NODE_ENV

const development = {
    channels: {
        website: '/citeve/website',
        homepage: '/citeve/website/homepage'
    }
}

const production = {
    channels: {
        website: '/citeve/website',
        homepage: '/citeve/website/homepage'
    }
}

const config = {
    development,
    production,
}

module.exports = config[env]