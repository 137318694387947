<template>
    <div><pre v-if="debug">{{model}}</pre><!-- Dummy div to be replaced --></div>
</template>
<script>
    import DataProvider from '../../assets/DataProvider';
    //import ComponentProvider from '../../assets/ComponentProvider';
    import ComponentBuilder from '../../assets/ComponentBuilder';

    import ArrayInput from '../forms/ArrayInput.vue';
    import ChannelItemsSelect from '../forms/ChannelItemsSelect.vue';
    import DateInputGroup from '../forms/DateInputGroup.vue';
    import HtmlInput from '../forms/HtmlInput.vue';
    import ImageDropTarget from '../forms/ImageDropTarget.vue';
    import PhoneNumberInput from '../forms/PhoneNumberInput.vue';

    import ChannelLink from '../helpers/ChannelLink.vue';
    import ContentLink from '../helpers/ContentLink.vue';
    import HeadSection from '../helpers/HeadSection.vue';

    import Block from '../views/Block.vue';
    import ChannelItems from '../views/ChannelItems.vue';
    import RoutedBlock from '../views/RoutedBlock.vue';


    import OpenLayersMap from '../maps/OpenLayers.vue'

    import { mapState } from 'vuex';

    export default {
        name: 'RoutedBlock',
        components: {
            ArrayInput,
            ChannelItemsSelect,
            DateInputGroup,
            HtmlInput,
            ImageDropTarget,
            PhoneNumberInput,

            ChannelLink,
            ContentLink,
HeadSection,
            HeadSection,

            Block,
            ChannelItems,
            RoutedBlock,

            OpenLayersMap
        },
        props: ['block', 'template'],
        data() {
            return {
                debug: false,
                model: null,
                templateId: '',
                preHooks: null,
                hookedLinks: [],
                rootPath: null,
                path: null,
            };
        },
        computed: {
            ...mapState(['appData'])
        },
        beforeMount() {
            var $rb = this;

            $rb.prevHooks = this.$router.beforeHooks;
            $rb.$router.beforeHooks = [];
            $rb.$router.beforeEach(this.beforeRoute);
            for (var h = 0; h < this.prevHooks.length; h++) {
                $rb.$router.beforeEach(this.prevHooks[h]);
            }

            $rb.model = $rb.block;
            $rb.templateId = $rb.template;

            $rb.path = $rb.rootPath = $rb.$route.path;

            if ($rb.model.Child) {
                var childPathArr = $rb.model.Child.ChannelPath.split('/');
                var childPath = childPathArr[childPathArr.length - 2];
                $rb.rootPath = $rb.$route.path.substr($rb.$route.path.length - childPath.length);
            }
            // debugger;
        },
        mounted() {
            var $rb = this;

            $rb.$nextTick(() => {
                // catch links for vue own route
                document.querySelectorAll('a[href="' + $rb.path + '"]').forEach((a) => {
                    $rb.hookedLinks.push(a);
                    a.addEventListener('click', $rb.aClick);
                });
            });

            if ($rb.model.Child) {
                DataProvider.getContentTemplate($rb.model.Child, $rb.$store).then(t => {
                    ComponentBuilder.buildBlockComponent($rb.model.Child, t, $rb.model.Child.Template.Id, $rb, {
                        ArrayInput,
                        ChannelItemsSelect,
                        DateInputGroup,
                        HtmlInput,
                        ImageDropTarget,
                        PhoneNumberInput,

                        ChannelLink,
                        ContentLink,
HeadSection,

                        Block,
                        ChannelItems,
                        RoutedBlock,

                        OpenLayersMap
                    });
                })
            }
            else if ($rb.template) {
                DataProvider.getTemplate($rb.template, $rb.$store).then(t => {
                    $rb.model.Template = $rb.model.Template || { Id: $rb.template.toLowerCase() };
                    ComponentBuilder.buildBlockComponent($rb.model, t, $rb.template, $rb, {
                        ArrayInput,
                        ChannelItemsSelect,
                        DateInputGroup,
                        HtmlInput,
                        ImageDropTarget,
                        PhoneNumberInput,

                        ChannelLink,
                        ContentLink,
HeadSection,

                        Block,
                        ChannelItems,
                        RoutedBlock,

                        OpenLayersMap
                    });
                })
                    .catch(e => {
                        $rb.debug = true;
                        $rb.model = e;
                    });

            } else {
                DataProvider.getContentTemplate($rb.model, $rb.$store).then(t => {
                    ComponentBuilder.buildBlockComponent($rb.model, t, $rb.model.Template.Id, $rb, {
                        ArrayInput,
                        ChannelItemsSelect,
                        DateInputGroup,
                        HtmlInput,
                        ImageDropTarget,
                        PhoneNumberInput,

                        ChannelLink,
                        ContentLink,
HeadSection,

                        Block,
                        ChannelItems,
                        RoutedBlock,
                    });
                })
                    .catch(e => {
                        console.error(e);
                        $rb.debug = true;
                        $rb.model = e;
                    });

            }

        },
        methods: {
            aClick() {
                var $rb = this;
                $rb.beforeRoute({ path: event.currentTarget.attributes.href.value }, { path: $rb.path }, () => { });
            },
            beforeRoute(to, from, next) {
                // called when the route that renders this component is about to
                // be navigated away from.
                // has access to `this` component instance.
                //debugger;
                var $rb = this;

                if (to.path.indexOf($rb.rootPath) != 0) // not related
                {
                    $rb.hookedLinks.forEach(a => { a.removeEventListener('click', $rb.aClick); })
                    $rb.$nextTick(() => {
                        $rb.$router.beforeHooks = [];
                        $rb.prevHooks.forEach(h => $rb.$router.beforeEach(h));
                    });
                    //carry on
                    next();
                    return;
                }

                if (to.path == $rb.path) {
                    next(false);
                    return;
                }

                document.querySelectorAll('.router-link-active').forEach(a => a.classList.remove('router-link-active'));
                document.querySelectorAll('[href="' + to.path + '"]').forEach(a => a.classList.add('router-link-active'));

                if (to.path == $rb.rootPath) { // back to origins
                    //debugger;
                    DataProvider.getTemplate($rb.templateId, $rb.$store).then(t => {

                        ComponentBuilder.buildBlockComponent($rb.model, t, $rb.templateId, $rb, {
                            ArrayInput,
                            ChannelItemsSelect,
                            DateInputGroup,
                            HtmlInput,
                            ImageDropTarget,
                            PhoneNumberInput,

                            ChannelLink,
                            ContentLink,
HeadSection,

                            Block,
                            ChannelItems,
                            RoutedBlock,
                        });

                        clearInterval(window.paceinterval);
                        document.body.classList.remove('pace-running');
                        document.body.classList.add('pace-done');
                        document.querySelector('.pace').classList.add('pace-inactive');
                    });

                    next(false);

                    history.pushState(
                        {},
                        null,
                        to.path);

                    $rb.path = to.path;

                    return;
                }
                else //is child
                {
                    DataProvider.getPropsData(
                        $rb.appData[$rb.$i18n.culture].HomeChannel.Path + to.path.replace(/\/*$/, ''),
                        $rb.$i18n.culture, $rb.$store, true)
                        .then(d => {
                            DataProvider.getContentTemplate(d, $rb.$store).then(t => {

                                ComponentBuilder.buildBlockComponent(d, t, d.Template.Id, $rb, {
                                    ArrayInput,
                                    ChannelItemsSelect,
                                    DateInputGroup,
                                    HtmlInput,
                                    ImageDropTarget,
                                    PhoneNumberInput,

                                    ChannelLink,
                                    ContentLink,
HeadSection,

                                    Block,
                                    ChannelItems,
                                    RoutedBlock,
                                });

                                clearInterval(window.paceinterval);
                                document.body.classList.remove('pace-running');
                                document.body.classList.add('pace-done');
                                document.querySelector('.pace').classList.add('pace-inactive');
                            });
                        });

                    next(false);

                    history.pushState(
                        {},
                        null,
                        to.path
                    )

                    $rb.path = to.path;
                    return;
                }
            },
        }
    }
</script>
<style>
</style>