<template>
    <div class="array-input">
        <div style="position: relative" v-if="valueModel" v-for="(input, idx) in valueModel" v-bind:key="idx">
            <input type="text" class="form-control" v-bind:value="valueModel[idx]" :placeholder="placeholder + ' (' + (idx+1) + ')'" v-on:input="updateValue(idx, $event.target.value)" />
            <button v-if="idx < valueModel.length - 1" class="btn btn-sm btn-flat btn-default" @click="removeEntry($event, idx)">x</button>
            <button v-if="idx == valueModel.length - 1" class="btn btn-sm btn-primary" @click="addEntry($event)">+</button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ArrayInput',
        props: {
            value: Array,
            placeholder: { type: String, default: '' }
        },
        data() {
            return {
                valueModel: this.value
            }
        },
        methods: {
            addEntry($event) {
                $event.preventDefault()
                this.valueModel = this.valueModel || [];
                this.valueModel.push('');
                this.$emit('input', [].concat(this.valueModel.filter(i => i)))
            },
            removeEntry($event, idx) {
                $event.preventDefault()
                this.valueModel.splice(idx, 1);
                this.$emit('input', [].concat(this.valueModel.filter(i => i)))
            },
            updateValue: function (idx, value) {
                //debugger
                this.valueModel[idx] = value
                this.$emit('input', [].concat(this.valueModel.filter(i => i)))
            }
        },
        mounted() {
            this.valueModel = this.valueModel || [];
            if (this.valueModel.length == 0)
                this.valueModel.push('');
            this.$emit('input', this.valueModel)
        }
    }
</script>
<style scoped>
    .array-input * {
        font-size: inherit;
    }

    .array-input > *:nth-child(n+2) {
        margin-top: inherit;
    }

    .array-input button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: .5em;
        display: block;
        border-radius: 0;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        height: 100%;
    }
</style>