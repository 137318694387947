<template>
    <div><div><!-- Dummy element to be replaced --></div></div>
</template>
<script>
    import DataProvider from '../../assets/DataProvider';
    import ComponentBuilder from '../../assets/ComponentBuilder';

    import Block from './Block.vue';
    import RoutedBlock from './RoutedBlock.vue';
    import ChannelItems from './ChannelItems.vue';

    import ArrayInput from '../forms/ArrayInput.vue';
    import ChannelItemsSelect from '../forms/ChannelItemsSelect.vue';
    import HtmlInput from '../forms/HtmlInput.vue';
    import ImageDropTarget from '../forms/ImageDropTarget.vue';
    import DateInputGroup from '../forms/DateInputGroup.vue';
    import PhoneNumberInput from '../forms/PhoneNumberInput.vue';

    import ChannelLink from '../helpers/ChannelLink.vue';
    import ContentLink from '../helpers/ContentLink.vue';
    import HeadSection from '../helpers/HeadSection.vue';

    import OpenLayersMap from '../maps/OpenLayers.vue'


    import * as VuetifyComponents from 'vuetify/lib'

    export default {
        name: 'Block',
        components: {
            Block,
            ChannelItems,
            RoutedBlock,
            ChannelItemsSelect,
            ChannelLink,
            ContentLink,
HeadSection,
            ArrayInput,
            HtmlInput,
            ImageDropTarget,
            DateInputGroup,
            PhoneNumberInput,
            OpenLayersMap,
            ...VuetifyComponents
        },
        props: ['block', 'template'],
        methods: {
        },
        data() {
            return {
                debug: false,
                model: null,
                templateId: ''
            };
        },
        mounted() {
            var $block = this;
            $block.model = $block.block;
            $block.templateId = $block.template;
            if ($block.template) {
                
                DataProvider.getTemplate($block.template, $block.$store).then(t => {
                    $block.model.Template = $block.model.Template || { Id: $block.template.toLowerCase() };
                    ComponentBuilder.buildBlockComponent($block.model, t, $block.template, $block, {
                        Block,
                        RoutedBlock,
                        ChannelItems,
                        ChannelItemsSelect,
                        ChannelLink,
                        ContentLink,
HeadSection,
                        ArrayInput,
                        HtmlInput,
                        ImageDropTarget,
                        DateInputGroup,
                        PhoneNumberInput,
                        OpenLayersMap,
                        ...VuetifyComponents
                    });
                })
                    .catch(e => {
                        $block.debug = true;
                        $block.model = e;
                    });

            } else {
                DataProvider.getContentTemplate($block.model, $block.$store).then(t => {
                    ComponentBuilder.buildBlockComponent($block.model, t, $block.model.Template.Id, $block, {
                        Block,
                        ChannelItems,
                        RoutedBlock,
                        ChannelItemsSelect,
                        ChannelLink,
                        ContentLink,
HeadSection,
                        ArrayInput,
                        HtmlInput,
                        ImageDropTarget,
                        DateInputGroup,
                        PhoneNumberInput,
                        OpenLayersMap,
                        ...VuetifyComponents
                    });
                })
                    .catch(e => {
                        $block.debug = true;
                        $block.model = e;
                    });

            }

        }
    }
</script>
<style>
</style>