<template>
    <div class="head-section">
        <slot></slot>
    </div>
</template>

<style scoped>
    .head-section {
        display: none;
    }
</style>

<script>
    export default {
        name: 'head-section',
        mounted() {
            let head = document.querySelector('head');

            //Store the original head on the window object for later reseting the head content
            if (!window.OriginalHead) {
                window.OriginalHead = head.innerHTML;
            }

            let newHeadContent = encodeURIComponent(this.htmlEncode(this.$el.innerHTML)); // newHeadContent is a url encoded html encoded string containing html code.
            //console.log(newHeadContent);

            //Check if another head-section component already exists on this page
            if (!window.newHeadContent) {
                window.newHeadContent = "";

                //Send a fetch request using the POST method to write the metadata key value pair on the server
                window.newHeadContent += newHeadContent;
                const href = `${window.location.host}${window.location.pathname}`;
                fetch(`/data/createmetatags?h=${href}&m=${window.newHeadContent}`);
            }
            else {
                console.error("A section-head element already exists in this page. Only one may exist per page.")
            }
        },
        watch: {
            $route(to, from) {
                //Reset head content on a route change
                document.querySelector('head').innerHTML = window.OriginalHead;
                window.newHeadContent = "";
            }
        },
        methods: {
            htmlEncode: (string) => {
                return string.replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    /*.replace(/>/g, '&gt;')
                    .replace(/'/g, '&#39;')
                    .replace(/"/g, '&#34;')
                    .replace(/\//, '&#x2F;')*/;
            }
        }
    }
</script>