
// convert radians to degrees
export function radToDeg(rad) {
    return rad * 360 / (Math.PI * 2);
}
// convert degrees to radians
export function degToRad(deg) {
    return deg * Math.PI * 2 / 360;
}
// modulo for negative values
export function mod(n) {
    return ((n % (2 * Math.PI)) + (2 * Math.PI)) % (2 * Math.PI);
}