<template>
    <div class="date-input-group">
        <span v-show="show">{{newDate}}</span>
        <div class="d-flex">
            <div class="form-group w-50">
                <input type="number" class="form-control" id="date_day" v-model.number="day" maxlength="2" min="1" max="31" placeholder="DD">
            </div>
            <div class="form-group w-100 px-3">
                <select class="form-control" id="date_month" v-model="month">
                    <option disabled value="">{{$trans('Selecione')}}</option>
                    <option value="1">{{$trans('Janeiro')}}</option>
                    <option value="2">{{$trans('Fevereiro')}}</option>
                    <option value="3">{{$trans('Mar&ccedil;o')}}</option>
                    <option value="4">{{$trans('Abril')}}</option>
                    <option value="5">{{$trans('Maio')}}</option>
                    <option value="6">{{$trans('Junho')}}</option>
                    <option value="7">{{$trans('Julho')}}</option>
                    <option value="8">{{$trans('Agosto')}}</option>
                    <option value="9">{{$trans('Setembro')}}</option>
                    <option value="10">{{$trans('Outubro')}}</option>
                    <option value="11">{{$trans('Novembro')}}</option>
                    <option value="12">{{$trans('Dezembro')}}</option>
                </select>
            </div>
            <div class="form-group w-100">
                <input type="number" class="form-control" id="date_year" v-model.number="year" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="4" placeholder="YYYY">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DateInputGroup',
        props: {
            value: { default: '1970-01-01' },
            placeholder: { default: '' },
            show: { default: false }
        },
        computed: {
            newDate() {
                // if it is recalculated
                let newD = new Date(Date.UTC(this.year, this.month - 1, this.day, 0));

                this.$emit('input', newD)
                /*eslint-disable*/ // we want this side-effect
                this.day = newD.getUTCDate();
                this.month = newD.getUTCMonth() + 1;
                this.year = newD.getUTCFullYear();

                return newD;
            }
            //day: {
            //    get: function () {
            //        console.log('get')
            //        return this.valueModel.getDate();
            //    },
            //    set: function (newValue) {
            //        console.log('set ' + newValue)
            //        this.valueModel.setDate(newValue);
            //    }
            //},
            //month: {
            //    get: function() {
            //        return this.valueModel.getMonth() + 1;
            //    },
            //    set: function(newValue) {
            //        this.valueModel.setMonth(newValue - 1);
            //    }
            //},
            //year: {
            //    get: function() {
            //        return this.valueModel.getFullYear();
            //    },
            //    set: function(newValue) {
            //        this.valueModel.setFullYear(newValue);
            //    }
            //},
        },
        data() {
            return {
                valueModel: new Date(),
                day: 1,
                month: 1,
                year: 1900
            }
        },
        methods: {

        },
        mounted() {
            //debugger;
            this.valueModel = typeof (this.value) == 'string' ? new Date(this.value) : this.value;
            this.day = this.valueModel.getDate();
            this.month = this.valueModel.getMonth() + 1;
            this.year = this.valueModel.getFullYear();
        }
    }
</script>
<style scoped>
    .array-input * {
        font-size: inherit;
    }

    .array-input > *:nth-child(n+2) {
        margin-top: inherit;
    }

    .array-input button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: .5em;
        display: block;
        border-radius: 0;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        height: 100%;
    }
</style>